import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFocusable, getCurrentFocusKey } from '@dstv-web-leanback/norigin-spatial-navigation'

import { ProgressBar } from '@dstv-web-leanback/dstv-frontend-components'
import { NAV_PUSH, getVideoId, useNavigation } from '@dstv-web-leanback/dstv-frontend-services'

import playicon from '../../../assets/images/play.png'
import posterFallback from '../../../assets/images/smarttv/poster_fallback.png'
import { getShowpageRoute } from '../../../utils/utils'
import styles from './CardPortrait.module.scss'

export const CardPortrait = ({ index, item, rowId, key, itemFocus, from, ...rest }) => {
	const dispatch = useDispatch()
	const { location, navigate } = useNavigation()
	const { onSelect, className } = rest

	const { ref, focused } = useFocusable({
		onFocus: () => {
			if (ref) itemFocus(ref, item, index)

			if (rest.getCardDetails && !item.fetched_info) {
				getCardDetails(item, rowId)
			}
		},
		onEnterPress: () => {
			if (!item.resume_state) {
				dispatch(NAV_PUSH({ key: getCurrentFocusKey(), location: location.pathname }))
				navigateToShowPage()
			}

			if (
				['resume', 'watch'].includes(item?.resume_state) &&
				item?.resume_video_id &&
				item?.id &&
				!item?.playerDetails &&
				onSelect
			) {
				onSelect({ ...item, rowId })
			}
		},
		focusKey: `CARD_${rowId ? rowId : ''}${index}`,
	})

	const { getCardDetails } = rest

	const navigateToShowPage = () => {
		const pageType = location.pathname.replace('/', '')
		const isCatchUp = ['tvshows', 'sport', 'kids', 'movies', 'channelgroups', 'mylist'].includes(pageType)
		const videoId = !isCatchUp ? item.id : getVideoId(item)

		const showpageRoute = getShowpageRoute(videoId)
		showpageRoute && navigate(showpageRoute)
	}

	useEffect(() => {
		if (index === 0 && item?.tracking_info?.row === 0) getCardDetails(item, rowId)

		let io = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				if (rest.getCardDetails && !item.fetched_info && !item.poster_image) {
					getCardDetails(item, rowId)
				}
			}
		})

		if (ref.current) {
			io.observe(ref.current)
		}

		return () => {
			if (ref.current) {
				io.unobserve(ref.current)
			}
		}
	}, [])

	return (
		<div ref={ref} className={`${styles.card_container} ${focused ? styles.active : ''} ${className}`}>
			<img
				className={styles['card_image']}
				src={item.poster_image || posterFallback}
				alt="Card Poster"
				onError={(e) => {
					e.target.src = posterFallback
				}}
			/>

			<div className={styles['card_overlay']}>
				{item.resume_state && <img alt="" className={styles.card_playButton} src={playicon} />}

				{item.resume_state && item.resume_progress_percentage > -1 && (
					<div className={styles.progress_wrapper}>
						<ProgressBar progress={item.resume_progress_percentage} />
					</div>
				)}
			</div>
		</div>
	)
}
export default CardPortrait
