import { useEffect } from 'react'
import { useFocusable, FocusContext } from '@dstv-web-leanback/norigin-spatial-navigation'

import { ChannelBlock, ChannelEventsRow, Timeline } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './ChannelsContainer.module.scss'

export function ChannelsContainer({
	changeGridFocus,
	channelIndex,
	channels,
	currentTime,
	eventsRef,
	gridFocused,
	getDayEvents,
	gridStart,
	playChannel,
	setGridFocused,
	wrapperRef,
	focusable,
}) {
	const { focusKey, hasFocusedChild, ref } = useFocusable({
		focusKey: 'CHANNELS_CONTAINER',
		trackChildren: true,
		saveLastFocusedChild: false,
		isFocusBoundary: true,
		focusBoundaryDirections: ['left'],
		focusable: focusable,
	})

	useEffect(() => {
		setGridFocused(hasFocusedChild)
	}, [hasFocusedChild])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.channels_container} ${gridFocused ? styles.active : ''}`}>
				<div ref={wrapperRef} className={styles.scroll_container}>
					<aside className={styles.grid_sidebar}>
						<header>
							<h3 className={styles.day_label}>Today</h3>
						</header>
						<div className={styles.channel_logos}>
							{channels.map((channel, index) => {
								if (index >= channelIndex - 1 && index <= channelIndex + 5) {
									return <ChannelBlock channel={channel} getDayEvents={getDayEvents} />
								}
							})}
						</div>
					</aside>
					<div ref={eventsRef} className={styles.events_wrapper}>
						<header>
							<Timeline gridStart={gridStart} currentTime={currentTime} />
						</header>
						{channels.map((channel, index) => {
							if (index >= channelIndex - 1 && index <= channelIndex + 5) {
								return (
									<div className={styles.channels_row}>
										<ChannelEventsRow
											currentTime={currentTime}
											channel={channel}
											gridStart={gridStart}
											itemFocus={changeGridFocus}
											playChannel={playChannel}
											rowIndex={index}
										/>
									</div>
								)
							}
						})}
					</div>
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default ChannelsContainer
