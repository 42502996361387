import styles from './Toast.module.scss';
import iconToastTick from '../../../assets/images/smarttv/toast_tick.svg';
export function Toast({ showToast, message }) {
	return (
		!!message && (
			<div
				className={[
					showToast ? styles.show : styles.hide,
					styles.toast,
				].join(' ')}
			>
				<img src={iconToastTick} alt="" />
				<p>{message}</p>
			</div>
		)
	);
}
export default Toast;
