import {
	REMOVE_PROFILE_CRUD,
	SET_ACTIVE_PROFILE_ID,
	GET_MENU,
	SET_FOCUSED_PROFILE,
} from '@dstv-web-leanback/dstv-frontend-services'
import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddAvatar } from '../../core/AddAvatar/AddAvatar'
import Profile from '../../core/Profile/Profile'
import styles from './ProfileList.module.scss'

export function ProfileList(props) {
	const {
		profileData: { items, canAdd },
		activeProfile,
		focusedProfileId,
	} = props

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { focusKey, focusSelf, ref } = useFocusable({
		trackChildren: true,
		isFocusBoundary: true,
		onBackPress: () => {
			if (activeProfile?.id) {
				navigate('/home')
			}
		},
	})

	useEffect(() => {
		if (focusedProfileId) {
			setFocus(focusedProfileId)
		} else if (activeProfile?.id) {
			setFocus(activeProfile?.id)
		} else if (items.length > 0) {
			focusSelf()
		}
	}, [dispatch, focusSelf, setFocus, activeProfile?.id, items, focusedProfileId])

	useEffect(() => {
		return () => {
			dispatch(REMOVE_PROFILE_CRUD())
		}
	}, [dispatch])

	const handleProfileSelect = (profileId) => {
		dispatch(SET_FOCUSED_PROFILE({ id: profileId }))
		dispatch(
			SET_ACTIVE_PROFILE_ID({
				id: profileId,
			})
		)
		dispatch(GET_MENU({}))

		navigate('/home')
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles['container']}>
				{items &&
					items.map((profile, index) => (
						<Profile
							avatarProps={{ index, total: items.length }}
							focusKey={profile.id}
							key={profile.id + profile.alias}
							profile={profile}
							onSelectItem={() => {
								handleProfileSelect(profile.id)
							}}
							onEditItem={() => {
								dispatch(SET_FOCUSED_PROFILE({ id: profile.id }))
								dispatch(REMOVE_PROFILE_CRUD())
								navigate(`/profiles/edit/${profile.id}`)
							}}
							index={index}
						/>
					))}

				{canAdd && (
					<AddAvatar
						key="add avatar"
						onSelectItem={() => {
							dispatch(SET_FOCUSED_PROFILE({ id: 'ADD_USER' }))
							navigate(`/profiles/new`)
						}}
					/>
				)}
			</div>
		</FocusContext.Provider>
	)
}
export default ProfileList
