import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import styles from './SectionMenu.module.scss'
import { SectionNavItem } from '@dstv-web-leanback/dstv-frontend-components'
import { SET_ACTIVE_SECTION, useNavigation } from '@dstv-web-leanback/dstv-frontend-services'

export function SectionMenu({ gridFocused, onSectionChange }) {
	const sectionsState = useSelector((state) => state.sections)
	const [menuScroll, setMenuScroll] = useState(0)
	const { location } = useNavigation()

	const dispatch = useDispatch()

	useEffect(() => {
		if (!location.back) {
			setMenuScroll(0)
			setFocus('SUBMENU_0')
		}
	}, [sectionsState.data, location.back])

	const { focusKey, ref } = useFocusable({
		focusKey: 'SECTION_MENU',
		preferredChildFocusKey: `SUBMENU_${sectionsState.activeSection}`,
		saveLastFocusedChild: false,
	})

	const changeMenuFocus = (index, focusedRef) => {
		let menuStyles = getComputedStyle(ref.current)
		setMenuScroll(focusedRef.current.offsetLeft - Number.parseInt(menuStyles.paddingLeft))
		dispatch(SET_ACTIVE_SECTION({ index: index }))
		onSectionChange && onSectionChange(index)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div className={`${styles.section_menu_wrapper} ${gridFocused && styles.hidden}`}>
				<div ref={ref} className={styles.section_menu} style={{ transform: `translate3d(-${menuScroll}px, 0, 0)` }}>
					{sectionsState?.data?.map((item, index) => {
						return (
							<SectionNavItem
								active={index === sectionsState.activeSection}
								id={item.id}
								index={index}
								itemFocus={changeMenuFocus}
								key={index}
								title={item.title}
							/>
						)
					})}
				</div>
			</div>
		</FocusContext.Provider>
	)
}

export default SectionMenu
