import { useEffect, useCallback, useRef } from 'react'

export const useDebounce = (delay, onDelay, onDelayComplete) => {
	const timer = useRef()

	const debounce = useCallback(
		(val) => {
			onDelay?.(val)
			clearTimeout(timer.current)
			timer.current = setTimeout(() => {
				onDelayComplete?.(val)
			}, delay)
		},
		[delay, onDelay, onDelayComplete]
	)

	useEffect(() => {
		return () => {
			clearTimeout(timer.current)
		}
	}, [])

	return debounce
}

export default useDebounce
