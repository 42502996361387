export const transformAppsResponse = (response, location) => {
	let list = response.items

	list.forEach((res, index, list) => {
		list[index] = {
			id: res?.id || '',
			heroImage: res?.images[1].href,
			logo: res?.images[0].href || '',
			title: res?.title,
			trackingInfo: res?.tracking_info,
			appName: res?.launch_info.app_name || '',
			url: res?.launch_info.url || '',
			appType: res?.launch_info.app_type || '',
			appLocation: location,
			type: response?.type,
		}
	})

	let row = {
		id: response?.id,
		items: list,
		links: response?.links,
		product: response?.product,
		time_to_refresh_in_seconds: response?.time_to_refresh_in_seconds,
		title: response?.title,
		type: response?.type,
	}

	return row
}

export const getRemoveContinueWatchingEndpoint = (item) => {
	return item.links
		?.find((o) => o.rel.includes('continue_watching_remove'))
		?.href?.replace('virtual://dstv_now_production', 'https://ssl.dstv.com/api/dstv_now')
}

export const getHomeRowEndpoint = (row) => {
	return row?.links
		?.find((o) => o.rel.includes('self'))
		?.href?.replace('virtual://dstv_now_production', 'https://ssl.dstv.com/api/dstv_now')
}
