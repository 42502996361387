import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ProgressBar } from '@dstv-web-leanback/dstv-frontend-components'
import styles from './Billboard.module.scss'
import showpageFallbackImgCardInfo from '../../../assets/images/streamaBillboardFallback.png'
import { useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

export function Billboard({ detailsVisible = false, onLoad }) {
	const billboardState = useSelector((state) => state.billboard)

	const [imageSrc, setImageSrc] = useState()
	const [info, setInfo] = useState(billboardState.info)
	const [infoChanged, setInfoChanged] = useState(false)

	const posterDebounce = useDebounce(
		200,
		() => {
			setInfoChanged(true)
			onLoad?.(true)
		},
		() => {
			if (billboardState.info?.poster) {
				const img = new Image()
				img.onload = () => {
					const imageUrlWithSpaces = billboardState.info?.poster
					const imageUrl = imageUrlWithSpaces ? imageUrlWithSpaces.replace(/ /g, '%20') : null

					setImageSrc(imageUrl)
					setInfo(billboardState.info)
					setInfoChanged(false)
					onLoad?.(false)
				}
				img.onerror = (error) => {
					setImageSrc(showpageFallbackImgCardInfo)
					setInfoChanged(false)
					onLoad?.(false)
				}
				img.src = billboardState.info?.poster

				return () => {
					img.onload = undefined
					img.onerror = undefined
					img.src = ''
				}
			} else if (billboardState.info?.synopsis) {
				setImageSrc(showpageFallbackImgCardInfo)
				setInfo(billboardState.info)
				setInfoChanged(false)
				onLoad?.(false)
			}
		}
	)

	useEffect(() => {
		if (!billboardState.loading) {
			posterDebounce()
		}
	}, [billboardState.info?.poster, billboardState.info?.metadata, billboardState.loading])

	return (
		<section
			className={`${styles.billboard} ${styles.cardInfoBillboard}`}
			key={billboardState.displayItemDetailedTitle}
		>
			<div
				key={
					'Billboard-' + billboardState.info?.title + billboardState.info?.channelNumber + billboardState.info?.metadata
				}
				className={styles['container-fluid']}
				id={
					'Billboard-' + billboardState.info?.title + billboardState.info?.channelNumber + billboardState.info?.metadata
				}
			>
				<div className={`${styles.billboard_details} ${!detailsVisible && styles.hide}`}>
					<div className={styles.billboard__inner}>
						<div>
							{/* logo */}
							<div className={styles.channelWrap}>
								{info?.channelLogo && (
									<div className={styles.channelLogo}>
										<img src={info?.channelLogo} alt="Channel Logo" />
									</div>
								)}
								<p className={styles.channelNumber}>
									{info?.channelNumber && info?.channelLogo ? info?.channelNumber : ''}
								</p>
							</div>

							{/* title */}
							{info?.images?.['show-logo']?.['XLARGE'] || billboardState?.info?.showLogo ? (
								<div className={`${!infoChanged ? styles.fade_in_move_info : styles.fade_out_move_info_with_delay}`}>
									<img
										className={styles.showLogo}
										src={info?.images?.['show-logo']?.['XLARGE'] || billboardState?.info?.showLogo}
										alt="Channel Logo"
									/>
								</div>
							) : (
								<h1 className={styles.header}>{billboardState?.title}</h1>
							)}

							<div className={`${!infoChanged ? styles.fade_in_move_info : styles.fade_out_move_info_with_delay}`}>
								{/* Display Item Detailed Title */}
								<p className={styles.bookmark_details}>{billboardState.displayItemDetailedTitle}</p>

								{/* Metadata */}
								<div className={styles.metadata_movies}>
									<ul className={styles.metadata}>{info?.metadata}</ul>
								</div>

								{/* Synopsis */}
								<p className={`${info?.airDate && info?.metadata ? styles.synopsisShort : styles.synopsisFull}`}>
									{info?.synopsis}
								</p>

								{/* Expiry Date */}
								{info?.airDate && (
									<div className={styles.airedInfo}>
										<span>Aired: {info?.airDate}</span>
										{info?.expiryDate && (
											<span className={styles.availDate}>
												<span>Available until:</span>
												{info?.expiryDate}
											</span>
										)}
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Progress */}
					<div
						className={`${!infoChanged ? styles.fade_in_move_info : styles.fade_out_move_info_with_delay} ${
							styles.progress_wrapper
						}`}
					>
						{Number.isInteger(billboardState.progress) && <ProgressBar progress={billboardState.progress} />}
					</div>
				</div>
			</div>

			<div className={`${styles.hero_image_wrapper_lrg}`}>
				{imageSrc && (
					<div style={{ backgroundImage: `url(${imageSrc})` }} className={`${styles.hero_image_wrapper}`}></div>
				)}
			</div>
		</section>
	)
}

export default Billboard
