import styles from './ProgressBar.module.scss';

export function ProgressBar({ progress }) {
	return (
		<div className={styles.progress_bar}>
      <div className={styles.progress} style={{ width: `${progress}%` }}></div>
    </div>
	);
}

export default ProgressBar;
