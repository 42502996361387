import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NAV_FEATURE_KEY, REPLACE_NAV_LOCATIONS } from '@dstv-web-leanback/dstv-frontend-services'

const blacklist = ['/play']

export const useNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { history } = useSelector((state) => state[NAV_FEATURE_KEY])

	const navigateBack = useCallback(
		(options) => {
			const newHistory = [...history]

			// remove current history item
			newHistory.pop()

			// remove all illegal entries if any
			while (blacklist.includes(newHistory[newHistory.length - 1]?.pathname)) {
				newHistory.pop()
			}

			// indicate back movement
			const lastItem = newHistory.pop()
			newHistory.push({
				...lastItem,
				back: true,
			})

			dispatch(REPLACE_NAV_LOCATIONS(newHistory))
			navigate(-(history.length - newHistory.length), options)
		},
		[history, navigate, dispatch]
	)

	return {
		location: history[history.length - 1],
		navigate,
		navigateBack,
	}
}

export default useNavigation
