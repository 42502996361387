import { createSlice } from '@reduxjs/toolkit'
import { mergeMap } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'

import { errorResponse, HTTP } from '../../utils/httpHelper'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { getEndpointsFromMenu } from '../../utils/storageHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'
import { transformAppsResponse, getHomeRowEndpoint } from './helper'
import { ENV } from '../../utils/environment'
import { SET_ACTIVE_PROFILE_ID } from '../profile/profile.slice'

export const HOME_FEATURE_KEY = 'home'

/*
 * Create our slice
 */
export const homeSlice = createSlice({
	name: HOME_FEATURE_KEY,
	initialState: { rows: [], active_carousel_index: 0 },
	reducers: {
		GET_HOME: (state) => {
			delete state.error
			delete state.serverError
			state.rows = []
			state.loading = true
		},
		GET_HOME_SUCCESS: (state, action) => {
			state.rows = prepareHomeRows(action.payload.rows)
			state.loading = false
			state.selectRowIndex = null
		},
		GET_HOME_ERROR: (state, action) => {
			state.error = action.payload.error
			state.serverError = action.payload.serverError
			state.loading = false
		},
		GET_HOME_CHANNEL_CARD_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_CHANNEL_CARD_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_EVENT_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_EVENT_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_CARD_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_CARD_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_CARD_INFO_ERROR: (state) => {
			state.loading = false
		},
		GET_HOME_VOD_CARD_INFO: (state) => {
			state.loading = true
		},
		GET_HOME_VOD_CARD_INFO_SUCCESS: (state, action) => {
			updateItemsWithCardInfoResponse(state, action)
			state.loading = false
		},
		GET_HOME_INFO_ERROR: (state) => {
			state.loading = false
		},
		SET_HOME_ACTIVE_CAROUSEL_INDEX: (state, action) => {
			state.active_carousel_index = action.payload.index
		},
		GET_HOME_ROW: (state, action) => {
			state.loading = true
		},
		GET_HOME_ROW_SUCCESS: (state, action) => {
			state.loading = false
			state.rows.forEach((row) => {
				if (row.id === action.payload.id) {
					row.items = action.payload.items.map((o) => {
						return {
							...row.items.find((item) => item.id === o.id),
							...o,
						}
					})
				}
			})
			state.focusRow = action.payload.focusRow
		},
		GET_HOME_ROW_ERROR: (state, action) => {
			state.focusRow = action.payload.focusRow
			state.loading = false
		},
		REMOVE_ITEM_FROM_ROW: (state, action) => {
			state.loading = true
		},
		CLEAR_FOCUS_ROW: (state, action) => {
			state.focusRow = null
		},
	},
	extraReducers: (builder) => {
		builder.addCase(SET_ACTIVE_PROFILE_ID.type, (state) => {
			state.rows = []
			state.active_carousel_index = 0
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const homeReducer = homeSlice.reducer

/*
 * Export actions
 */
export const {
	GET_HOME,
	GET_HOME_SUCCESS,
	GET_HOME_ERROR,
	GET_HOME_INFO_ERROR,
	GET_HOME_CHANNEL_CARD_INFO,
	GET_HOME_CHANNEL_CARD_INFO_SUCCESS,
	GET_HOME_EVENT_INFO,
	GET_HOME_EVENT_INFO_SUCCESS,
	GET_HOME_CARD_INFO,
	GET_HOME_CARD_INFO_SUCCESS,
	GET_HOME_VOD_CARD_INFO,
	GET_HOME_VOD_CARD_INFO_SUCCESS,
	SET_HOME_ACTIVE_CAROUSEL_INDEX,
	GET_HOME_ROW,
	GET_HOME_ROW_SUCCESS,
	GET_HOME_ROW_ERROR,
	REMOVE_ITEM_FROM_ROW,
	CLEAR_FOCUS_ROW,
} = homeSlice.actions

/*
 * Set up the redux-observable epic
 */
export const homeEpic = (action$) =>
	action$.pipe(
		ofType(
			GET_HOME.type,
			GET_HOME_CHANNEL_CARD_INFO.type,
			GET_HOME_EVENT_INFO.type,
			GET_HOME_VOD_CARD_INFO.type,
			GET_HOME_CARD_INFO.type,
			GET_HOME_ROW.type,
			REMOVE_ITEM_FROM_ROW.type
		),
		mergeMap(homeService(action$))
	)

/*
 * Do API calls
 */
const homeService = (action$) => (action) => {
	switch (action.type) {
		case GET_HOME.type: {
			const endpointFromMenu = getEndpointsFromMenu('home')
			return HTTP.GET(endpointFromMenu, getHeaders(), getHomeSuccess(action), homeError(action), true)
		}
		case GET_HOME_CHANNEL_CARD_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeChannelCardInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_EVENT_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeEventInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_VOD_CARD_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeVodCardInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_CARD_INFO.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeCardInfoSuccess(action), homeInfoError(action), true)
		}
		case GET_HOME_ROW.type: {
			let endpoint = action.payload.url
			return HTTP.GET(endpoint, getHeaders(), getHomeRowSuccess(action), homeRowError(action), true)
		}
		case REMOVE_ITEM_FROM_ROW.type: {
			let endpoint = action.payload.url
			return HTTP.DELETE(endpoint, null, getHeaders(), getContinueWatching(action), homeError(action), true)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const getHomeSuccess = () => (response) => {
	response.items.map((row) => {
		if (row.items) {
			row.items.map((item) => {
				item.poster_image = getCardPosterImageURL(item)
			})
		}
	})

	return { type: GET_HOME_SUCCESS.type, payload: { rows: response.items } }
}

// This is for the home rows.
const getHomeChannelCardInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_CHANNEL_CARD_INFO_SUCCESS.type,
		payload: {
			response: response,
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'channel',
		},
	}
}

// This is for the current live event on a tv channel card
const getHomeEventInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_EVENT_INFO_SUCCESS.type,
		payload: {
			response: response.events[0],
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'event',
		},
	}
}

// This is for VOD cards
const getHomeVodCardInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_VOD_CARD_INFO_SUCCESS.type,
		payload: {
			response: response,
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'card',
		},
	}
}

const getHomeCardInfoSuccess = (action) => (response) => {
	return {
		type: GET_HOME_CARD_INFO_SUCCESS.type,
		payload: {
			response: response,
			resume_progress: action?.payload.item?.resume_progress_percentage,
			rowId: action.payload.rowId,
			cardId: action.payload.item.id,
			type: 'card',
		},
	}
}

const getContinueWatching = (action) => (response) => {
	const row = action.payload.rows.find((o) => o.id === action.payload.item.rowId)
	return {
		type: GET_HOME_ROW.type,
		payload: {
			focusRow: action.payload.focusRow,
			rowId: row.id,
			url: getHomeRowEndpoint(row),
		},
	}
}

const getHomeRowSuccess = (action) => (response) => {
	return {
		type: GET_HOME_ROW_SUCCESS.type,
		payload: {
			items: response.items.map((item) => {
				return {
					...item,
					poster_image: getCardPosterImageURL(item),
				}
			}),
			id: action.payload.rowId,
			focusRow: action.payload.focusRow,
		},
	}
}

const homeRowError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_HOME_ROW_ERROR.type,
				payload: {
					...errorResponse(response, action),
					focusRow: action.payload.focusRow,
				},
			},
			action
		)
	)
}

const homeError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_HOME_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const homeInfoError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_HOME_INFO_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

//Potentially need a better name for this
export const updateItemsWithCardInfoResponse = (state, action) => {
	// Adding try->catch because sometimes response is empty
	try {
		let rowIndex = state.rows.findIndex((row) => row.id === action.payload.rowId)
		const currentCard = state.rows[rowIndex].items?.find((card) => card.id === action.payload.cardId)

		//Add all the info from the response to the existing object
		for (const [key, value] of Object.entries(action.payload.response)) {
			currentCard[action.payload.type + '_' + key] = value
			if (key === 'show-logo') {
				const showLogoValue = value // This will retrieve the value of 'show-logo'
				// Perform actions using showLogoValue here
				currentCard.showLogo = showLogoValue
			}
		}

		currentCard.resume_progress_percentage = currentCard.resume_progress_percentage || null

		//Handle the poster
		currentCard.showLogo = currentCard?.card_images?.[0]?.href
		currentCard.poster_image = currentCard.poster_image || getCardPosterImageURL(currentCard)
		currentCard.fetched_info = true
	} catch (e) {
		console.log(e)
	}
}

export const updateVodPlayerDetails = (state, action) => {
	// Adding try->catch because sometimes response is empty
	try {
		let rowIndex = state.rows.findIndex((row) => row.id === action.payload.rowId)
		const currentCard = state.rows[rowIndex].items?.find((card) => card.id === action.payload.cardId)

		currentCard.playerDetails = action.payload.data
	} catch (e) {
		console.log(e)
	}
}

const prepareHomeRows = (rows) => {
	rows.forEach((row, index, rows) => {
		if (row.type === 'apps') {
			rows[index] = transformAppsResponse(row, 'home')
		}
	})

	return rows
}
