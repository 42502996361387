import { getVideoId, getVideoIdByGenRef } from '../../utils/transformHelper'
import { formatTimeToHoursAndMinutes } from '../../utils/utils'

export const getBillboardPosterURL = (response, type) => {
	switch (type) {
		case 'event': {
			let data =
				response &&
				response.event_images &&
				response.event_images.filter((item) => {
					return item.rel[0] === 'thumbnailUri' && item.rel[1] === 'XLARGE'
				})
			return data?.[0]?.href
		}
		case 'card': {
			let data =
				response &&
				response.card_images &&
				response.card_images.filter((item) => {
					return item.rel[0] === 'hero' && item.rel[1] === 'MEDIUM'
				})
			return data?.[0]?.href
		}
	}
}

export const getBillboardShowLogoURL = (response, type) => {
	switch (type) {
		case 'event': {
			let data =
				response &&
				response.event_images &&
				response.event_images.filter((item) => {
					return item.rel[0] === 'show-logo' && item.rel[1] === 'XLARGE'
				})
			return data?.[0]?.href
		}
		case 'card': {
			let data =
				response &&
				response.card_images &&
				response.card_images.filter((item) => {
					return item.rel[0] === 'show-logo' && item.rel[1] === 'XLARGE'
				})
			return data?.[0]?.href
		}
	}
}

const getLiveTVBillboardData = (item) => {
	let data = {
		title: item?.title,
		poster: item?.image,
		synopsis: item?.description,
		metadata:
			formatTimeToHoursAndMinutes(item?.start) +
			' - ' +
			formatTimeToHoursAndMinutes(item?.end) +
			'   ' +
			(item?.metaData?.[0]?.value ? `${item.metaData[0].value.toUpperCase()} | ` : '') +
			(item.rating && item.maturityClassification ? `${item.rating} ${item.maturityClassification} | ` : '') +
			(item?.secondaryGenre ? item.secondaryGenre.toUpperCase().replace('|', ', ') : ''),
	}
	return data
}

const getChannelsMetadata = (item) => {
	const formatTime = (time) => (time ? formatTimeToHoursAndMinutes(time) : '')

	const startTime = formatTime(item?.event_start_date_time) || formatTime(item?.start_date_time)
	const endTime = formatTime(item?.event_end_date_time) || formatTime(item?.end_date_time)

	const rating = item?.event_rating ? ` | ${item.event_rating.toUpperCase()}` : ''
	const genre =
		item?.card_genres && item.card_genres.length
			? ` | ${item.card_genres.map((genre) => genre.toUpperCase()).join(', ')}`
			: ''

	const time = startTime && endTime ? `${startTime} - ${endTime}` : ''
	const metadata = time + rating + genre

	return metadata
}

const getVideosMetadata = (item) => {
	const age = item?.card_age_restricted_to_age ? item.card_age_restricted_to_age : ''
	const ratingAdvisory = item?.card_rating_advisory ? item.card_rating_advisory : ''
	const genre = item?.card_genres ? item.card_genres.join(',').toUpperCase() : ''
	const genreString = genre.length > 1 && age.length > 1 ? ` | ${genre}` : genre
	return age + ratingAdvisory + genreString
}

const getDefaultMetadata = (item) => {
	const cardProgram = item?.card_program?.seasons?.[0]
	const video = cardProgram?.videos?.[0]
	const shortTitle = cardProgram?.displayCollectionShortTitle || ''
	const value = video?.metaData?.[1]?.value || ''
	const ratingAdvisory = video?.ratingAdvisory || ''

	const valueString = value.length > 1 && shortTitle.length > 1 ? ` | ${value}` : value
	const ratingAdvisoryString =
		ratingAdvisory.length > 1 && shortTitle.length > 1 ? ` | ${ratingAdvisory}` : ratingAdvisory

	return shortTitle + valueString + ratingAdvisoryString
}

export const getHomeBillboardData = (item) => {
	let data = {}
	//Transform the data from the card info to billboard usable object
	switch (item?.type?.toLowerCase()) {
		//Recently watched channels - Live TV Channels

		case 'channels': {
			data = {
				metadata: getChannelsMetadata(item),
				poster: getBillboardPosterURL(item, 'event'),
				subtitle: item?.event_episode_title,
				synopsis: item?.event_synopsis,
				title: item?.event_main_title || item?.title,
				channelNumber: item?.number,
				channelLogo: item?.images[0]?.href,
				showLogo: getBillboardShowLogoURL(item, 'card'),

				moreInfoEndpoint: item?.moreInfoEndpoint,
			}
			return data
		}
		//Some VOD cards are videos
		case 'videos': {
			data = {
				airDate: item?.card_airDate,
				expiryDate: item?.card_expiryDate,
				metadata: getVideosMetadata(item),
				poster: getBillboardPosterURL(item, 'card'),
				showLogo: getBillboardShowLogoURL(item, 'card'),
				synopsis: item?.card_synopsis,
				title: item?.title,
			}
			return data
		}

		// App Cards
		case 'apps': {
			data = {
				poster: item?.heroImage,
			}
			return data
		}
		//Some are programs
		case 'programs': {
			// Program types with card_synopsis seem to only be on Continue Watching
			if (item.card_synopsis) {
				data = {
					airDate: item?.card_airDate,
					expiryDate: item?.card_expiryDate,
					metadata: getVideosMetadata(item),
					poster: getBillboardPosterURL(item, 'card'),
					showLogo: getBillboardShowLogoURL(item, 'card'),
					synopsis: item?.card_synopsis,
					title: item?.card_title,
				}
				return data
			}
			//For all other program types NOT in Continue Watching
			else {
				data = {
					metadata: getDefaultMetadata(item),
					poster: item?.card_program?.images?.hero?.LARGE,
					showLogo: getBillboardShowLogoURL(item, 'card'),
					synopsis: item?.card_program?.seasons?.[0]?.videos?.[0]?.synopsis,
					title: item?.title,
					channelLogo: item?.card_program?.channels?.[0]?.images?.logos?.MEDIUM,
					channelNumber: item?.card_program?.channels?.[0]?.channelNumber,
				}
				return data
			}
		}
	}
}

const getBillboardDetails = (item, type = null) => {
	let data = null

	if (type === 'home') {
		data = getHomeBillboardData(item)
	}
	if (type === 'livetv') {
		data = getLiveTVBillboardData(item)
	} else if (item?.program) {
		data = {
			metadata:
				(item?.program?.seasons?.[0].displayCollectionShortTitle
					? `${item?.program?.seasons?.[0]?.displayCollectionShortTitle} | `
					: '') +
				(item?.program?.seasons?.[0]?.videos?.[0]?.metaData?.[1]?.value
					? `${item?.program?.seasons?.[0]?.videos?.[0]?.metaData?.[1]?.value} `
					: '') +
				(item?.program?.seasons?.[0]?.videos?.[0]?.ratingAdvisory
					? item?.program?.seasons?.[0]?.videos?.[0]?.ratingAdvisory
					: ''),
			poster: item?.program?.images?.hero?.MEDIUM,
			synopsis: item?.program?.seasons?.[0]?.videos?.[0]?.synopsis,
			title: item?.program?.title,
			channelLogo: item?.program?.channels?.[0]?.images?.logos?.MEDIUM,
			channelNumber: item?.program?.channels?.[0]?.channelNumber,
			images: item?.program?.images,
		}
	} else if (item?.video) {
		const metadata = [
			item?.video?.metaData?.[0]?.value,
			item?.video?.ageRestrictedToAge,
			item?.video?.ratingAdvisory,
			item?.video?.genres?.join(',').toUpperCase(),
		].filter((item) => !!item)

		data = {
			metadata: metadata.join(' | '),
			poster: item?.video?.images?.hero?.MEDIUM,
			synopsis: item?.video?.synopsis,
			title: item?.video?.title,
			channelLogo: item?.video?.channels?.[0]?.images?.logos?.MEDIUM,
			channelNumber: item?.video?.channels?.[0]?.channelNumber,
		}
	}
	return { ...data, moreInfoEndpoint: item?.moreInfoEndpoint }
}

export const getEpisodeDisplayItemTitle = (seasonNumber, episodeNumber, displayItemTitle) => {
	const episodeTitle = episodeNumber ? `Ep${episodeNumber} - ${displayItemTitle}` : displayItemTitle
	return seasonNumber && episodeNumber ? `S${seasonNumber}, ${episodeTitle}` : episodeTitle
}

const getEpisodeTitle = (data) => {
	const seasonNumber = data?.seasonNumber
	const episodeNumber = data?.seasonEpisode
	const displayItemTitle = data?.displayItemTitle
	return getEpisodeDisplayItemTitle(seasonNumber, episodeNumber, displayItemTitle)
}

export const getVodDetails = (item, currentVideoId) => {
	let videoObj = null
	let resume_video_id = undefined
	if (item?.program) {
		if (currentVideoId) {
			videoObj = item?.program?.seasons?.flatMap((o) => o.videos)?.find((o) => o.genRef === currentVideoId)
		}

		if (!videoObj) {
			videoObj = item?.program?.seasons?.[0]?.videos?.[0]
		}

		resume_video_id = videoObj?.genRef
	} else if (item?.video) {
		videoObj = item.video
	} else {
		videoObj = item
		resume_video_id = videoObj?.genRef
	}

	const data = videoObj && {
		url: videoObj?.videoAssets?.[0]?.url,
		details: {
			...videoObj,
			title: videoObj?.displayTitle,
			episode_title: getEpisodeTitle(videoObj),
			contentId: videoObj?.videoAssets?.[0]?.manItemId,
			resumeVideoId: videoObj?.resume_video_id || resume_video_id,
			genref: currentVideoId ? getVideoIdByGenRef(item, currentVideoId) : getVideoId(item),
		},
		type: 'vod',
	}

	return data
}

export const getWatchlistDetails = (data, loading) => {
	let title = null
	if (loading) {
		title = 'Loading'
	} else if (data?.isWatchlistItem) {
		title = 'Added to My List'
	} else {
		title = 'Removed from My List'
	}
	return { ...data, title }
}

export const getBillboardData = (data, type = null, videoId = null) => {
	const billboardData = {
		billboardDetails: getBillboardDetails(data, type),
		vodDetails: getVodDetails(data),
		watchlistDetails: getWatchlistDetails(data?.preferences, false),
		episodeDetails: data?.program,
		movieDetails: data?.video,
	}
	return { data: billboardData, videoId, date: new Date().getTime() }
}

export const getNextEpisodeData = (episodeDetails, resumeVideoId) => {
	const videoData = resumeVideoId && episodeDetails?.seasons?.[0]?.videos?.find((item) => item.genRef === resumeVideoId)

	if (videoData) {
		return {
			synopsis: videoData.synopsis,
			displayItemDetailedTitle: videoData.displayItemDetailedTitle,
			durationInSeconds: videoData.durationInSeconds,
		}
	}
	return null
}
