import styles from './PlayerButton.module.scss'

import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'

export function PlayerButton({ focusKey, icon, logo, onSelect, label, round }) {
	const { ref, focused } = useFocusable({
		onEnterPress: onSelect,
		focusKey: focusKey,
	})

	return (
		<div
			ref={ref}
			className={`${styles.player_button} ${focused ? styles.focus : ''} ${label ? styles.has_label : ''}`}
		>
			{icon && <img src={icon} />}
			{focused && (
				<>
					<span></span>
					{label && <div>{label}</div>}
					{logo && (
						<div className={styles.player_button_logo}>
							<img src={logo} />
						</div>
					)}
				</>
			)}
		</div>
	)
}

export default PlayerButton
