import { useEffect, useRef, useState } from 'react'
import { useFocusable, FocusContext, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useDispatch, useSelector } from 'react-redux'

import {
	Iplate,
	PlayerButton,
	PlayerProgressBar,
	PlayerSettingsModal,
} from '@dstv-web-leanback/dstv-frontend-components'
import { SAVE_AUDIO_LANGUAGE, SAVE_BANDWIDTH, useNavigation } from '@dstv-web-leanback/dstv-frontend-services'
import { useDebounce } from '@dstv-web-leanback/dstv-frontend-utils'

// Image assets
import iconCog from '../../../../assets/images/icons/icon-player-cog.svg'
import iconNext from '../../../../assets/images/icons/icon-player-next.svg'
import iconPause from '../../../../assets/images/icons/icon-player-pause.svg'
import iconPlay from '../../../../assets/images/icons/icon-player-play.svg'
import iconPrev from '../../../../assets/images/icons/icon-player-prev.svg'

import styles from '../PlayerControls.module.scss'
import { getDefaultSubOptionsValues, getPlayerSettingsOptions } from './helper'

export function LiveControls({
	audioLanguages,
	handleAudioChange,
	handleBandwidthChange,
	handleSubOptionChange,
	playing,
	playPause,
	switchChannel,
}) {
	// Variables
	const CONTROLS_TIMEOUT = 7000
	const [iplateActive, setIplateActive] = useState(false)
	const [prevChannel, setPrevChannel] = useState({})
	const [nextChannel, setNextChannel] = useState({})
	const [controlsVisible, setControlsVisible] = useState(true)
	const [showPlayerSettings, setShowPlayerSettings] = useState(false)

	const dispatch = useDispatch()
	const { navigateBack } = useNavigation()

	const channelsState = useSelector((state) => state.channels)
	const playerState = useSelector((state) => state.player)
	const configState = useSelector((state) => state.config)
	const adPlaying = useSelector((state) => state.adPlayer.playing)

	const options = useRef([])

	const { focusKey, focusSelf, ref } = useFocusable({
		focusKey: 'LIVE_CONTROLS',
		forceFocus: true,
		isFocusBoundary: true,
		preferredChildFocusKey: 'PLAY_PAUSE',
		onArrowPress: () => {
			debounce()
			setFocus('PLAY_PAUSE')
		},
		onBackPress: () => {
			if (controlsVisible) {
				hideControls()
			} else navigateBack()
		},
	})

	const debounce = useDebounce(CONTROLS_TIMEOUT, showControls, hideControls)

	// Effects
	useEffect(() => {
		focusSelf()
		debounce()
		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
			setShowPlayerSettings(false)
		}
	}, [])

	useEffect(() => {
		if (!adPlaying) debounce()
	}, [adPlaying])

	// When the controls become visible, set focus to the PLAY_PAUSE button
	useEffect(() => {
		if (controlsVisible) {
			setFocus('PLAY_PAUSE')
		}
	}, [controlsVisible])

	useEffect(() => {
		options.current = getPlayerSettingsOptions(configState, audioLanguages)

		if (!playerState.savedAudioLanguage && !playerState.savedBandwidth) {
			const defaultLanguage = audioLanguages?.[0]?.language
			const defaultBandwidth = +localStorage.getItem('QUALITY_STORAGE_BITRATE') || 6000000

			dispatch(SAVE_AUDIO_LANGUAGE({ language: defaultLanguage }))
			dispatch(SAVE_BANDWIDTH({ bandwidth: defaultBandwidth }))

			handleAudioChange(defaultLanguage)
			handleBandwidthChange(defaultBandwidth)
		}
	}, [])

	// Functions
	const handleKeyDown = (e) => {
		switch (e.keyCode) {
			case 40: // Down arrow
				// disabled until fix is in place for iplate
				// setIplateActive(true)
				break
			default:
				break
		}
	}

	const onCogBtnSelect = () => {
		setShowPlayerSettings(!showPlayerSettings)
	}

	function showControls() {
		setControlsVisible(true)
	}

	function hideControls() {
		setControlsVisible(false)
	}

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={`${styles.live_controls_wrapper} ${controlsVisible && styles.visible}`}>
				{!iplateActive && controlsVisible && (
					<div className={styles.player_controls}>
						<div className={styles.metadata_wrapper}>
							<h1>{playerState.details.title}</h1>
						</div>
						<div className={styles.times_wrapper}>
							<div className={styles.times_right}>
								<span className={styles.live_dot} /> Live
							</div>
						</div>
						<div className={styles.progress_wrapper}>
							<PlayerProgressBar focusable={false} progress={100} />
						</div>
						<div className={styles.controls_wrapper}>
							<div className={styles.controls_left}>
								<PlayerButton icon={iconCog} onSelect={onCogBtnSelect} selected={showPlayerSettings} />
							</div>
							<div className={styles.controls_center}>
								<PlayerButton
									focusKey={'PLAY_PAUSE'}
									icon={playing ? iconPause : iconPlay}
									onSelect={() => {
										playPause()
									}}
								/>
							</div>
							<div className={styles.controls_right}></div>
						</div>
					</div>
				)}

				{showPlayerSettings && (
					<PlayerSettingsModal
						options={options.current}
						defaultValues={getDefaultSubOptionsValues(
							options.current,
							playerState.savedAudioLanguage,
							playerState?.savedBandwidth
						)}
						handleBackButton={() => {
							setShowPlayerSettings(null)
							setIplateActive(false)
						}}
						handleSubOptionSelection={(optionHeading, subOption) => {
							setIplateActive(false)
							setShowPlayerSettings(false)
							setControlsVisible(false)
							handleSubOptionChange(optionHeading, subOption)
						}}
					/>
				)}

				{!showPlayerSettings && iplateActive && <Iplate active={iplateActive} switchChannel={switchChannel} />}
			</div>
		</FocusContext.Provider>
	)
}
export default LiveControls
