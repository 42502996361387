import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { ProgressBar } from '@dstv-web-leanback/dstv-frontend-components'

import styles from './CardIplate.module.scss'
import playIcon from '../../../assets/images/smarttv/play_button.png'

export function CardIplate({ active, item, itemFocus, switchChannel }) {
	const playerState = useSelector((state) => state.player)

	const { number, logo, tag } = item
	const { title: nextTitle } = item?.events?.[1] || {}
	const {
		endDateTime,
		episodeTitle,
		longSynopsis,
		maturityClassification,
		rating,
		startDateTime,
		thumbnailImagePaths: image,
		title: currentTitle,
	} = item?.events?.[0] || {}

	const { ref, focused } = useFocusable({
		onFocus: () => {
			itemFocus(ref)
		},
		onEnterPress: () => {
			switchChannel(tag)
		},
	})

	const getTimes = () => {
		let startTime = moment(startDateTime).format('hh:mm')
		let endTime = moment(endDateTime).format('hh:mm')

		return `${startTime} - ${endTime}`
	}

	let showInfo = (
		<div className={styles.info_wrapper}>
			<h3 className={styles.title}>{currentTitle}</h3>
			<span className={styles.metadata}>{getTimes()}</span>
			<div className={styles.progress_wrapper}>
				<ProgressBar progress={50} />
			</div>
		</div>
	)

	let focusedShowInfo = (
		<div className={styles.info_wrapper}>
			<h3 className={styles.title}>{currentTitle}</h3>
			<h5 className={styles.subtitle}>{episodeTitle}</h5>
			<span className={styles.metadata}>
				{getTimes()} | {rating} {maturityClassification}
			</span>
			<p className={styles.synopsis}>{longSynopsis}</p>
			<span className={styles.onNext}>On Next: {nextTitle}</span>
			<div className={styles.progress_wrapper}>
				<ProgressBar progress={50} />
			</div>
		</div>
	)

	return (
		<div ref={ref} className={`${styles.card_iplate} ${focused ? styles.active : ''}`}>
			{active && (
				<>
					<div className={styles.channel_details}>
						<img className={styles.channel_logo} src={logo} />
						<div className={styles.channel_number}>{number}</div>
					</div>
					<div className={styles.label_wrapper}>
						{tag === playerState.details.channelTag ? (
							<span className={`${styles.label} ${styles.accent}`}>WATCHING</span>
						) : (
							<span className={styles.label}>ON NOW</span>
						)}
					</div>
				</>
			)}
			<div className={styles.card_body}>
				<div className={styles.img_wrapper}>
					{focused && tag !== playerState.details.channelTag && <img className={styles.play_icon} src={playIcon} />}
					<img src={image.THUMB} />
				</div>
				{focused ? focusedShowInfo : showInfo}
			</div>
		</div>
	)
}
export default CardIplate
