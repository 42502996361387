import styles from './CardApps.module.scss'
import { useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import { useEffect } from 'react'
// import {Apps} from '@dstv-web-leanback/dstv-frontend-components'
export function CardApps({ itemFocus, item }) {
	const { ref, focused } = useFocusable({
		onFocus: () => {
			itemFocus(ref, item)
		},
	})

	return (
		<div ref={ref} className={`${styles.card_app} ${focused ? styles.active : ''}`}>
			<div className={styles.img_wrapper}>{item && <img src={item?.logo} alt={item?.title} />}</div>
		</div>
	)
}
export default CardApps
