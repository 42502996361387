import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'

import {
	DialogFull,
	ProfileDelete,
	ProfileEdit,
	ProfileNameEdit,
	ProfileNew,
	Profiles,
	ProfileSave,
} from '@dstv-web-leanback/dstv-frontend-components'
import {
	AVATARS_FEATURE_KEY,
	CLEAR_PROFILE_ERROR,
	GET_TERMS,
	PROFILE_FEATURE_KEY,
	SET_TERMS,
	TERMS_FEATURE_KEY,
} from '@dstv-web-leanback/dstv-frontend-services'
import { getErrorDetails } from '../../utils/helper'

import { termsDialog } from '../../utils/content'

import styles from './UserProfiles.module.scss'

export function UserProfiles(props) {
	const profilesState = useSelector((state) => state[PROFILE_FEATURE_KEY])
	const avatarsState = useSelector((state) => state[AVATARS_FEATURE_KEY])
	const termsState = useSelector((state) => state[TERMS_FEATURE_KEY])

	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!termsState.status) {
			dispatch(GET_TERMS())
		}
	}, [termsState.status])

	let error = null

	if (profilesState.error ?? avatarsState.error) {
		error = (
			<DialogFull
				{...getErrorDetails([profilesState, avatarsState])}
				backAction={() => {
					window.location.href = window.location.origin + window.location.pathname
				}}
				retryAction={() => {
					navigate(0)
				}}
				customAction={(url) => {
					url && navigate(url)
					dispatch(CLEAR_PROFILE_ERROR())
				}}
			/>
		)
	}

	if (!!termsState.status && termsState.status !== 'accepted') {
		return (
			<DialogFull
				customAction={(_url) => {
					dispatch(SET_TERMS({ accepted: true }))
				}}
				error={termsDialog}
				isError={false}
			/>
		)
	}

	return (
		<div className={styles.settings_page_wrapper}>
			{error && error}
			<Routes>
				{['/:id', '/:id/edit'].map((path) => (
					<Route key="ProfileNew" path={path} element={<ProfileNew />} />
				))}
				{['/edit/name/:id', '/save/edit/name/:id'].map((path) => (
					<Route key="ProfileNameEdit" path={path} element={<ProfileNameEdit />} />
				))}
				<Route key="ProfileEdit" path="/edit/:id" element={<ProfileEdit />} />
				<Route key="ProfileSave" path="/save/:id" element={<ProfileSave />} />
				<Route key="ProfileDelete" path="/delete/:id" element={<ProfileDelete />} />
				<Route path="/" element={<Profiles />} />
			</Routes>
		</div>
	)
}
export default UserProfiles
