import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FocusContext, useFocusable, setFocus } from '@dstv-web-leanback/norigin-spatial-navigation'

import {
	CLEAR_NEW_PROFILE_DETAILS,
	CREATE_PROFILE,
	DELETE_PROFILE_FLAGS,
	EDIT_PROFILE,
	GET_PROFILES_LIST,
	PROFILE_FEATURE_KEY,
	useNavigation,
} from '@dstv-web-leanback/dstv-frontend-services'
import {
	Loader,
	DialogButtonGroup,
	ProfileImage,
	ProfileName,
	getNewProfileDetails,
} from '@dstv-web-leanback/dstv-frontend-components'

import styles from './ProfileSave.module.scss'
import dstvLogo from '../../../../assets/images/dstv_logo_fallback.png'

export function ProfileSave(props) {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { navigateBack } = useNavigation()
	const { id } = useParams()

	const profiles = useSelector((state) => state[PROFILE_FEATURE_KEY].data?.items)
	const loading = useSelector((state) => state[PROFILE_FEATURE_KEY].loading)
	const newProfile = useSelector((state) => state[PROFILE_FEATURE_KEY].newProfile)

	const profileCrud = useSelector((state) => state[PROFILE_FEATURE_KEY].profileCrud)

	const isNewProfile = id === 'new'

	let selectedProfile = null

	if (!isNewProfile) {
		selectedProfile = profiles?.find((item) => item.id === id)
	}

	let { alias, avatarUri, avatarId } = getNewProfileDetails(selectedProfile, newProfile)

	const [titleKey, setTitleKey] = useState('save')

	const saveTitle = id === 'new' ? 'Create your profile' : 'Update your profile'

	const { ref, focusKey } = useFocusable({
		onBackPress: () => {
			if (isNewProfile) {
				navigateBack()
			} else {
				navigate(`/profiles`)
			}
		},
	})

	const title = {
		avatar: 'Edit your avatar',
		name: 'Edit your name',
		save: saveTitle,
	}

	const actionButtons = [
		{
			focusKeyParam: 'BUTTON_SAVE',
			title: 'Save',
			onItemFocus: () => {
				setTitleKey('save')
			},
			action: (payload) => {
				if (id === 'new') {
					dispatch(
						CREATE_PROFILE({
							alias,
							avatarId,
							profileId: '',
						})
					)
				} else {
					dispatch(
						EDIT_PROFILE({
							alias,
							avatarId,
							profileId: id,
						})
					)
				}
			},
		},
	]

	useEffect(() => {
		setFocus('BUTTON_SAVE')
	}, [setFocus])

	useEffect(() => {
		if (profileCrud) {
			dispatch(CLEAR_NEW_PROFILE_DETAILS())
			dispatch(DELETE_PROFILE_FLAGS())
			dispatch(GET_PROFILES_LIST())
			navigate('/profiles')
		}
	}, [profileCrud, navigate, dispatch])

	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} className={styles.profile__confirm}>
				<img className={styles.dstv_logo} alt="DStv logo" src={dstvLogo} />
				{loading ? <Loader /> : null}
				<div className={styles.title}>{title[titleKey]}</div>
				<ProfileImage
					imageType="profile"
					alias={alias}
					avatarUri={avatarUri}
					from="save_page"
					onSelectItem={() => {
						if (isNewProfile) {
							navigate('/profiles/new/edit')
						} else {
							navigate('/profiles/edit/' + id)
						}
					}}
					onItemFocused={() => setTitleKey('avatar')}
					index={-1}
				/>
				<ProfileName id={id} profileName={alias} setTitleKey={() => setTitleKey('name')} from="save_page" />
				<DialogButtonGroup actionButtons={actionButtons} orientation="vertical" />
			</div>
		</FocusContext.Provider>
	)
}
export default ProfileSave
