//import { getSmartTvResolution } from '../Utils/platform'
// Platform Config.
export const ENV_MODE = process.env.NODE_ENV
export const DEV_MODE = ENV_MODE === 'development'
export const shouldBenchmark = process.env.NX_ENABLE_PERFORMANCE_METRICS === '2'
export const productId = process.env.NX_PLATFORM_ID || '1b09957b-27aa-493b-a7c9-53b3cec92d63'
export const packageId = process.env.NX_PACKAGE_ID || '3e6e5480-8b8a-4fd5-9721-470c895f91e2'
export const BITMOVIN_ANALYTICS_KEY = process.env.NX_BITMOVIN_ANALYTICS_KEY || 'fade2fe8-0e20-4996-9f15-0bac4dcca32c'
export const BITMOVIN_DOMAIN = process.env.NX_BITMOVIN_DOMAIN || 'dstv.com'
export const PUSHER_KEY = process.env.NX_PUSHER_KEY || '5b1cf858986ab7d6a9d7'
export const SECRET_KEY = process.env.NX_SECRET_KEY || '@#$2345678@#$%^'

export const EXPLORA_TEMP_PLATFORM_ID =
	process.env.NX_EXPLORA_TEMP_PLATFORM_ID || '32faad53-5e7b-4cc0-9f33-000092e85950'
export const BOOKMARK_THRESHOLD = process.env.NX_BOOKMARK_THRESHOLD || 93
export const DATA_DOG_APPLICATION_ID = process.env.NX_DATA_DOG_APPLICATION_ID || '353b47ac-c44e-4981-8f34-14d05b178101'
export const DATA_DOG_CLIENT_TOKEN = process.env.NX_DATA_DOG_CLIENT_TOKEN || 'pub731233ceb3adec46e059b68e97347352'
export const DATA_DOG_SITE = process.env.NX_DATA_DOG_SITE || 'datadoghq.eu'
export const DATA_DOG_SERVICE = process.env.NX_DATA_DOG_SERVICE || 'leanback-2.0-perfomance'
export const DATA_DOG_ENV = /staging|localhost|sit|suitest/.test(window.location.hostname) ? 'dev' : 'prod'
export const DATA_DOG_SAMP_RATE = process.env.NX_DATA_DOG_SAMP_RATE || 20
export const DATA_DOG_REPLAY_SAMP_RATE = process.env.NX_DATA_DOG_REPLAY_SAMP_RATE || 5
export const APP_VERSION = APP_VERSION || 'Leanback 2.0 Performance'

export const DATADOG_CONFIG = {
	applicationId: DATA_DOG_APPLICATION_ID,
	clientToken: DATA_DOG_CLIENT_TOKEN,
	site: DATA_DOG_SITE,
	service: DATA_DOG_SERVICE,
	env: DATA_DOG_ENV,
	version: APP_VERSION,
	sessionSampleRate: DATA_DOG_SAMP_RATE,
	sessionReplaySampleRate: DATA_DOG_REPLAY_SAMP_RATE,
}
const getPlatformId = () => {
	switch (window.deployment_platform) {
		case 'explora':
			return '7228971d-040d-4eea-bd9c-d1b241eca011'
		case 'smarttv':
			return window.deployment_device_type === 'Xbox'
				? '7228971d-040d-4eea-bd9c-d1b241eca011'
				: 'f8113a08-286b-4250-b7c5-31fbfcaec8b0'
		case 'streama':
			return '7869f714-c674-4386-8a05-8e37b619bb68'
		case 'chromecast':
			return 'f8113a08-286b-4250-b7c5-31fbfcaec8b0'
		default:
			return '32faad53-5e7b-4cc0-9f33-000092e85950'
	}
}

const getDeviceType = () => {
	switch (window.deployment_platform) {
		case 'smarttv':
			return 'deviceType=Smart TV'
		case 'streama':
			return 'deviceType=Streama MDMP100'
		default:
			return 'deviceType=Web'
	}
}

const getRevisionNumberByPlatformId = () => {
	switch (getPlatformId()) {
		default:
			return '5'
	}
}

export const getSegmentId = () => {
	switch (window.deployment_environment) {
		case 'STAGING_LG':
		case 'STAGING_WEBOS':
		case 'STAGING_WEB0S':
			return 'iU2CX5HyDDo7xecP5crN8nBtFytTLkFV'
		case 'STAGING_HISENSE':
			return 'jxdcJn0jpAfaCFzTXpl3Bdp9dgdPdNBY'
		case 'STAGING_XBOX':
			return '9bOBY8Uy5O0ZCftsZNiVoZMo7dWXj5gZ'
		case 'STAGING_SAMSUNG':
			return 'IHFWqofNwHlv9i64ixcxubT5xuO8mR4k'
		case 'STAGING_EXPLORA':
			return 'swG7uAPNZ2bskRxNp4lnbFFw9EgxEKvn'
		case 'STAGING_STREAMA':
			return 'COsDGEyin0YLUTslo9PAE3hJphYSE9Y1'

		case 'PRODUCTION_LG':
		case 'PRODUCTION_WEBOS':
		case 'PRODUCTION_WEB0S':
			return '5uemYAdReviLedFySMovOh8e5wvPBg6F'
		case 'PRODUCTION_HISENSE':
			return '1aX5V30fzduMMYP7Cw6PgCaYSF9aR1BZ'
		case 'PRODUCTION_XBOX':
			return 'q7cNYGLyTsacyBdtYhPWYAo9TF1lwBNz'
		case 'PRODUCTION_SAMSUNG':
			return '0wIybhrtrZ7kqLeRtArBM1FZHMkTLtyy'
		case 'PRODUCTION_EXPLORA':
			return 'XJAkdSfFjBzBOOa4BG7OAhU2Z9SFcF2z'
		case 'PRODUCTION_STREAMA':
			return '8CW2rBqQt8YiC0pkb49MIrSbz6ZSmcxO'

		case 'PRODUCTION':
			return 'HQYDJg6nVSloaf5746neLAPozIolCPew'
		default:
			return 'juaj5IYNp5WYlmViyTIVeLsewuuEvgXf'
	}
}

export const segmentId = getSegmentId()
export const platformId = getPlatformId()
export const DEVICE_TYPE = getDeviceType()

// export const stagingSegmentId = window.deployment_environment && window.deployment_environment === "PRODUCTION" ? "HQYDJg6nVSloaf5746neLAPozIolCPew" : "HQYDJg6nVSloaf5746neLAPozIolCPew";//"juaj5IYNp5WYlmViyTIVeLsewuuEvgXf";
// export const APP_VERSION = "2.0";

export const exploraTempPlatformId = '32faad53-5e7b-4cc0-9f33-000092e85950'

// export const DEV_PROXY_URL = '' //10.75.23.3:9000/proxy?url=

// export const SHOWMAX_API_VERSION = "119.5";
// export const SHOWMAX_IMAGE_SIZE = "240";
// export const BITMOVIN_ANALYTICS_KEY = "fade2fe8-0e20-4996-9f15-0bac4dcca32c";
// export const BOOKMARK_THRESHOLD = 93;
// export const BITMOVIN_DOMAIN = "dstv.com";

export const CONFIG = {
	PRODUCT_ID: `productId=${productId}`,
	PLATFORM_ID: `platformId=${platformId}`,
	PLATFORM_ID_MENU: `platform_id=${platformId}`,
	PACKAGE_ID: `packageId=${packageId}`,
	EXPLORA_PLATFORM_ID: `platformId=${exploraTempPlatformId}`,
}

// export const SMARTTV_RESOLUTION = getSmartTvResolution()
// export const APP_LINKS = {
//     IOS: 'https://itunes.apple.com/za/app/dstv-now/id467168196?mt=8',
//     ANDROID: 'https://play.google.com/store/apps/details?id=com.dstvmobile.android'
// }

// export const SOCIAL_APP_LINKS = {
//     FACEBOOK: 'https://www.facebook.com/DStvSouthAfrica',
//     TWITTER: 'https://twitter.com/dstv'
// }
// export const SHOWMAX_EXCLUDE_LIST = '?exclude%5b%5d=' + ['videos', 'cast', 'seasons', 'crew', 'categories', 'has_download_policy', 'id', 'type', 'metadata_language', 'metadata_direction', 'ratings', 'vod_model', 'section', 'metadata_direction', 'metadata_language', 'year', 'subtitles_languages', 'trial', 'slug', 'audio_languages', 'description', 'estimated_count_episodes', 'network', 'valid_subscription_statuses', 'provider'].join('&exclude%5b%5d=');

export const PAGE_SIZE = `pageSize=${window.deployment_platform === 'smarttv' ? 60 : 30}`
// export const BOXOFFICE_PAGE_SIZE = `page_size=${
// 	window.deployment_platform === 'smarttv' ? 60 : 30
// }`;
const PRODUCT_PLATFORM_PACKAGE_ID = `${CONFIG.PRODUCT_ID};${CONFIG.PLATFORM_ID};${CONFIG.PACKAGE_ID}`
const PRODUCT_PLATFORM_ID_DEVICE_TYPE = `${CONFIG.PRODUCT_ID};${CONFIG.PLATFORM_ID};${DEVICE_TYPE}`
export const PRODUCT_PLATFORM_ID = `${CONFIG.PRODUCT_ID};${CONFIG.PLATFORM_ID};`
// const PRODUCT_PLATFORM_ID_STREAMA = `${CONFIG.PRODUCT_ID};${CONFIG.PLATFORM_ID}`;
// const EXPLORA_PRODUCT_PLATFORM_ID = `${CONFIG.PRODUCT_ID};${CONFIG.EXPLORA_PLATFORM_ID};${DEVICE_TYPE}`;

// cs-mobile Version.
export const API_VERSION = process.env.NX_API_VERSION || 'v7'

let key = {
	VERSION: 'version',
	ENVIRONMENT: 'env',
}

const getEnvironment = () => {
	let environment = switchVersionEnv(key.ENVIRONMENT)
	if (!environment) {
		switch (window.api_environment) {
			case 'ALPHA':
				environment = 'ALPHA'
				break
			case 'BETA':
				environment = 'BETA'
				break
			case 'PRODUCTION':
				environment = 'PROD'
				break
			default:
				environment = 'PROD'
		}
		return environment
	}
}

const switchVersionEnv = (key) => {
	let querys = window.location.search.split('&')

	let versionFilter = querys.filter((e) => {
		return e.indexOf(key) !== -1
	})
	if (versionFilter.length > 0) {
		let valueSplit = versionFilter[0].split('=')
		console.log(valueSplit)
		return valueSplit[1]
	}
	return ''
}

// const getGranularAPIBaseEndpoint = (url) => {
// 	let finalURL = url;
// 	switch(url){
// 		case 'virtual://dstv_now_production':
// 			finalURL = url.replace('virtual://dstv_now_production', 'https://ssl.dstv.com/api/dstv_now')
// 	}
// }

export const BASE_API_END_POINT = {
	ALPHA: 'https://alpha-ssl.dstv.com/api',
	BETA: 'https://beta-ssl.dstv.com/api',
	PROD: 'https://ssl.dstv.com/api',
}

export const BASE_URL = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/cs-mobile/',
	BETA: 'https://beta-ssl.dstv.com/api/cs-mobile/',
	PROD: 'https://ssl.dstv.com/api/cs-mobile/',
}

// export const ENTITLEMENT_BASE_URL = {
//     ALPHA: "https://alpha-ssl.dstv.com/api/cs-vod-entitlement",
//     BETA: "https://beta-ssl.dstv.com/api/cs-vod-entitlement",
//     PROD: "https://ssl.dstv.com/api/cs-vod-entitlement",
// }

// export const AUTHENTICATION_REDIRECT = {
//     ALPHA: "http://stagingapps.dstv.com/connect/lcc/4.1/DStvNowApp/oauth/?response_type=id_token%20token&redirect_uri={REDIRECT_URI}&nonce=4123432766&scope=openid&client_id=23ff69ae-aa82-4116-b992-618f935f0273&prompt=login",
//     BETA: "https://connect.dstv.com/registration/signin?response_type=id_token%20token&redirect_uri={REDIRECT_URI}&nonce=4123432766&scope=openid&client_id=dc09de02-de71-4181-9006-2754dc5d3ed3&prompt=login",
//     PROD: "https://connect.dstv.com/registration/signin?response_type=id_token%20token&redirect_uri={REDIRECT_URI}&nonce=4123432766&scope=openid&client_id=dc09de02-de71-4181-9006-2754dc5d3ed3&prompt=login",
// }

// export const AUTHENTICATION_SIGNUP_REDIRECT = {
//     ALPHA: "https://connect.dstv.com/registration/signup/?redirect_uri={REDIRECT_URI}&nonce=4123432766&client_id=dc09de02-de71-4181-9006-2754dc5d3ed3&response_type=id_token%20token&scope=openid",
//     BETA: "https://connect.dstv.com/4.1/DStvNowApp?response_type=id_token%20token&redirect_uri={REDIRECT_URI}&nonce=4123432766&scope=openid&client_id=dc09de02-de71-4181-9006-2754dc5d3ed3",
//     PROD: "https://connect.dstv.com/4.1/DStvNowApp?response_type=id_token%20token&redirect_uri={REDIRECT_URI}&nonce=4123432766&scope=openid&client_id=dc09de02-de71-4181-9006-2754dc5d3ed3",
// }

// export const MANAGE_PROFILE_REDIRECT_ENV = {
//     ALPHA: "https://stagingapps.dstv.com/connect/registration/link/manage-details?access_token={ACCESS_TOKEN}",
//     BETA: "https://connect.dstv.com/registration/link/manage-details/?redirect_uri={REDIRECT_URI}&access_token={ACCESS_TOKEN}",
//     PROD: "https://connect.dstv.com/registration/link/manage-details/?redirect_uri={REDIRECT_URI}&access_token={ACCESS_TOKEN}",
// }

// export const UPDATE_ACCOUNT_REDIRECT_ENV = {
//     ALPHA: "https://stagingapps.dstv.com/connect/registration/link?existing=True&access_token={ACCESS_TOKEN}&redirectUrl={REDIRECT_URI}",
//     BETA: "https://connect.dstv.com/4.1/DStvNowApp/Smartcards?redirect_uri={REDIRECT_URI}&access_token={ACCESS_TOKEN}",
//     PROD: "https://connect.dstv.com/4.1/DStvNowApp/Smartcards?redirect_uri={REDIRECT_URI}&access_token={ACCESS_TOKEN}",
// }

// export const LINK_MY_ACCOUNT_REDIRECT_ENV = {
//     ALPHA: "https://stagingapps.dstv.com/connect/registration/link?existing=True&access_token={ACCESS_TOKEN}&redirectUrl={REDIRECT_URI}",
//     BETA: "https://connect.dstv.com/registration/signup/link-account/?access_token={ACCESS_TOKEN}&redirectUrl={REDIRECT_URI}&nonce={}&client_id=dc09de02-de71-4181-9006-2754dc5d3ed3",
//     PROD: "https://connect.dstv.com/registration/signup/link-account/?access_token={ACCESS_TOKEN}&redirectUrl={REDIRECT_URI}&nonce={}&client_id=dc09de02-de71-4181-9006-2754dc5d3ed3",
// }

// export const SESSION_REFRESH_ENV = {
//     ALPHA: "https://alpha-ssl.dstv.com/api/staging/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3",
//     BETA: "https://beta-ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3",
//     PROD: "https://ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3",
// }

export const GET_DAY_EVENTS = {
	ALPHA: `https://alpha-ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/epgEvents/byDate;channelTag={CHANNEL_TAG};date={DATE}`,
	BETA: `https://beta-ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/epgEvents/byDate;channelTag={CHANNEL_TAG};date={DATE}`,
	PROD: `https://ssl.dstv.com/api/cs-mobile/v7/epg-service/channels/epgEvents/byDate;channelTag={CHANNEL_TAG};date={DATE}`,
}

export const GET_MENU = {
	ALPHA: `https://alpha-ssl.dstv.com/api/navigation_menu?country_code={COUNTRY_CODE}&subscription_package={PACKAGE}&${
		CONFIG.PLATFORM_ID_MENU
	}&revision=${getRevisionNumberByPlatformId()}`,
	BETA: `https://ssl.dstv.com/api/navigation_menu?country_code={COUNTRY_CODE}&subscription_package={PACKAGE}&${
		CONFIG.PLATFORM_ID_MENU
	}&revision=${getRevisionNumberByPlatformId()}`,
	PROD: `https://ssl.dstv.com/api/navigation_menu?country_code={COUNTRY_CODE}&subscription_package={PACKAGE}&${CONFIG.PLATFORM_ID_MENU}&revision=6`,
}

export const GET_PROGRAM = {
	ALPHA: `https://alpha-ssl.dstv.com/api/cs-mobile/now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
	BETA: `https://beta-ssl.dstv.com/api/cs-mobile/now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
	PROD: `https://ssl.dstv.com/api/cs-mobile/now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
}

// export const VERIFY_OTP = 5
//     ALPHA: "https://alpha-ssl.dstv.com/api/lean-back-otp/device/verification",
//     BETA: "https://beta-ssl.dstv.com/api/lean-back-otp/device/verification",
//     PROD: "https://ssl.dstv.com/api/lean-back-otp/device/verification",
// }

export const AUTO_PLAY_NEXT_EPISODE = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/dstv_now/continue_watching/videos/{id}/auto_play',
	BETA: 'https://beta-ssl.dstv.com/api/dstv_now/continue_watching/videos/{id}/auto_play',
	PROD: 'https://ssl.dstv.com/api/dstv_now/continue_watching/videos/{id}/auto_play',
}

export const UPDATE_WATCHBUTTON_API = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/dstv_now/continue_watching/{type}/{id}/info',
	BETA: 'https://beta-ssl.dstv.com/api/dstv_now/continue_watching/{type}/{id}/info',
	PROD: 'https://ssl.dstv.com/api/dstv_now/continue_watching/{type}/{id}/info',
}

export const DEVICE_REGISTRATION = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/lean-back-otp/device/registration',
	BETA: 'https://beta-ssl.dstv.com/api/lean-back-otp/device/registration',
	PROD: 'https://ssl.dstv.com/api/lean-back-otp/device/registration',
}

export const REFRESH_TOKEN_API = {
	ALPHA: 'https://alpha-ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3',
	BETA: 'https://alpha-ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3',
	PROD: 'https://ssl.dstv.com/connect/connect-authtoken/v2/accesstoken/refresh?build_nr=1.0.3',
}

// export const STATIC_PAGE_CONTENT = {
//     ALPHA: "https://alpha-now.dstv.com/worldCupSchedule.json",
//     BETA: "https://beta-now.dstv.com/worldCupSchedule.json",
//     PROD: "https://now.dstv.com/worldCupSchedule.json",
// }

// export const GET_APPLIST = {
//     ALPHA: "https://alpha-ssl.dstv.com/api/apps",
//     BETA: "https://beta-ssl.dstv.com/api/apps",
//     PROD: "https://ssl.dstv.com/api/apps",
// }

export const GET_USER_INFO = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/user/info',
	BETA: 'https://beta-ssl.dstv.com/api/user/info',
	PROD: 'https://ssl.dstv.com/api/user/info',
}

export const GET_PROXIMITY_STATUS = {
	ALPHA: 'https://alpha-ssl.dstv.com/proximity',
	BETA: 'hhttps://beta-ssl.dstv.com/proximity/status',
	PROD: 'https://ssl.dstv.com/proximity/status',
}

export const GET_SEARCH = {
	ALPHA: `https://alpha-ssl.dstv.com/api/cs-mobile/now-content/v7/search;${CONFIG.PLATFORM_ID};searchTerm={SEARCH_TERM}`,
	BETA: `https://beta-ssl.dstv.com/api/cs-mobile/now-content/v7/search;${CONFIG.PLATFORM_ID};searchTerm={SEARCH_TERM}`,
	PROD: `https://ssl.dstv.com/api/cs-mobile/now-content/v7/search;${CONFIG.PLATFORM_ID};searchTerm={SEARCH_TERM}`,
}

export const GET_APPLIST = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/apps`,
	BETA: ` ${BASE_API_END_POINT.BETA}/apps`,
	PROD: `${BASE_API_END_POINT.PROD}/apps`,
}
// export const GET_IRDETO_SESSION = {
//     ALPHA: "https://alpha-ssl.dstv.com/api/vod-auth/entitlement/session",
//     BETA: "https://beta-ssl.dstv.com/api/vod-auth/entitlement/session",
//     PROD: "https://ssl.dstv.com/api/vod-auth/entitlement/session",
// }
export const GET_ENTITLEMENT = {
	ALPHA: 'https://alpha-ssl.dstv.com/api/vod-auth/entitlement/session',
	BETA: 'https://beta-ssl.dstv.com/api/vod-auth/entitlement/session',
	PROD: 'https://ssl.dstv.com/api/vod-auth/entitlement/session',
}

export const GET_CDN_ACCESS_TOKEN = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/play_stream/access_token?channel_tag={channel_tag}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/play_stream/access_token?channel_tag={channel_tag}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/play_stream/access_token?channel_tag={channel_tag}`,
}

export const GET_AD_REQUEST_API_URL = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/channels/{channel_tag}/events?number_of_events={num_of_events}`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/channels/{channel_tag}/events?number_of_events={num_of_events}`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/channels/{channel_tag}/events?number_of_events={num_of_events}`,
}

// // BOXOFFICE
// export const GET_RENTAL_MOVIES_URL = {
//     ALPHA: "https://ssl.dstv.com/api/dstv_now/boxoffice_rentals",
//     BETA: "https://ssl.dstv.com/api/dstv_now/boxoffice_rentals",
//     PROD: "https://ssl.dstv.com/api/dstv_now/boxoffice_rentals",
// }

// export const GET_BOXOFFICE_BASE_URL = {
//     ALPHA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_movies/ZA/',
//     BETA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_movies/ZA/',
//     PROD: 'https://ssl.dstv.com/api/dstv_now/boxoffice_movies/ZA/',
// }

// export const GET_BOXOFFICE_ALLOW_PAYMENT_METHODS = {
//     ALPHA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/payments/allowed_payment_methods',
//     BETA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/payments/allowed_payment_methods',
//     PROD: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/payments/allowed_payment_methods',
// }

// export const GET_BOXOFFICE_RENTAL_URL = {
//     ALPHA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/',
//     BETA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/',
//     PROD: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/',
// }

// export const GET_MOVIE_ENTITLEMENT = {
//     ALPHA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/{MOVIEID}/authorization',
//     BETA: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/{MOVIEID}/authorization',
//     PROD: 'https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/{MOVIEID}/authorization',
// }

// export const MANAGE_PROFILE_REDIRECT = MANAGE_PROFILE_REDIRECT_ENV[switchVersionEnv(key.ENVIRONMENT) || window.deployment_environment === "STAGING" ? "STAGING" : "BETA"];
// export const UPDATE_ACCOUNT_REDIRECT = UPDATE_ACCOUNT_REDIRECT_ENV[switchVersionEnv(key.ENVIRONMENT) || window.deployment_environment === "STAGING" ? "STAGING" : "BETA"];
// export const LINK_MY_ACCOUNT = LINK_MY_ACCOUNT_REDIRECT_ENV[switchVersionEnv(key.ENVIRONMENT) || window.deployment_environment === "STAGING" ? "STAGING" : "BETA"];
// export const REGISTER_REDIRECT = "https://connect.dstv.com/LCC/4.1/Register/?response_type=id_token%20token&redirect_uri={REDIRECT_URI}";

// export const AUTHENTICATION_EXPERIMENT_ENABLED = 'https://experiment.dstv.com/exp-redirect.html?t=signin&r={REDIRECT_URI}&d=' + encodeURIComponent(AUTHENTICATION_REDIRECT[window.deployment_environment === "STAGING" ? "STAGING" : "BETA"]);

// export const QR_CODE_REDIRECT_URL = {
//     ALPHA: "https://alpha-now.dstv.com/tv?userCode={USER_CODE}",
//     BETA: "https://beta-now.dstv.com/tv?userCode={USER_CODE}",
//     PROD: "https://now.dstv.com/tv?userCode={USER_CODE}",
// }

// export const EMAIL_SIGN_IN_URL = {
//     //Not available in BETA
//     ALPHA: "https://alpha-ssl.dstv.com/api/auth/sign-in/v1",
//     PROD: "https://ssl.dstv.com/api/auth/sign-in/v1",
// };

// export const EMAIL_SIGN_UP_URL = {
//     //Not available in BETA
//     ALPHA: "https://alpha-ssl.dstv.com/api/auth/sign-up/v1",
//     PROD: "https://ssl.dstv.com/api/auth/sign-up/v1",
// };

// export const EMAIL_PASSWORD_RESET_URL = {
//     //Not available in BETA
//     ALPHA: "https://alpha-ssl.dstv.com/api/auth/pwd-reset/v1",
//     PROD: "https://ssl.dstv.com/api/auth/pwd-reset/v1",
// };

// const STREAMA_CLIENT_ID = {
//     //25e9e50a-ddc8-4a86-851f-375260de973e
//     ALPHA: "23ff69ae-aa82-4116-b992-618f935f0273",
//     PROD: "44da6d62-e52d-48e7-8bc0-d421832dcdc3",
// };

export const TERMS_AND_CONDITIONS = {
	ALPHA: `${BASE_API_END_POINT.ALPHA}/dstv_now/terms_and_conditions`,
	BETA: `${BASE_API_END_POINT.BETA}/dstv_now/terms_and_conditions`,
	PROD: `${BASE_API_END_POINT.PROD}/dstv_now/terms_and_conditions`,
}

// export const GET_STREAMA_CLIENT_ID = STREAMA_CLIENT_ID[getEnvironment()];

// const STREAMA_REDIRECT_URL = {
//     ALPHA: "http://localhost",
//     PROD: "http://localhost"
// };

// export const GET_STREAMA_REDIRECT_URL = STREAMA_REDIRECT_URL[getEnvironment()];

// export const GET_PACKAGE_LIST = {
//     ALPHA:
//         "https://alpha-ssl.dstv.com/api/auth/package-details/v1",
//     PROD:
//         "https://ssl.dstv.com/api/auth/package-details/v1",
// };

// export const GET_SUBSCRIPTION_LINKED_STATUS = {
//     ALPHA: "https://alpha-ssl.dstv.com/api/auth/sign-in/v1/is_linked/{CONNECT_ID}",
//     PROD: "https://ssl.dstv.com/api/auth/sign-in/v1/is_linked/{CONNECT_ID}"
// };

//Environment Variables.
export const ENV = {
	BASE_URL: BASE_URL[getEnvironment()],
	BASE_API_END_POINT: BASE_API_END_POINT[getEnvironment()],
	DELETE_DEVICE: `user-manager/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/deregisterDevice;videoPackageId=3e6e5480-8b8a-4fd5-9721-470c895f91e2;${CONFIG.PRODUCT_ID};deviceId=`,
	DEVICE_REGISTRATION: DEVICE_REGISTRATION[getEnvironment()],
	GET_ALL_DEVICES: `user-manager/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/getDevices;videoPackageId=3e6e5480-8b8a-4fd5-9721-470c895f91e2;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};`,
	GET_MENU: GET_MENU[getEnvironment()],
	GET_DAY_EVENTS: GET_DAY_EVENTS[getEnvironment()],
	GET_PROFILES_LIST: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles`,
	REFRESH_TOKEN_API: REFRESH_TOKEN_API[getEnvironment()],
	REGISTER_DEVICE: `user-manager/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/vod-authorisation;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};deviceId=`,
	GET_USER_INFO: GET_USER_INFO[getEnvironment()],
	GET_PROXIMITY_STATUS: GET_PROXIMITY_STATUS[getEnvironment()],
	REMOTE_CONFIGS: 'https://features.dstv.com/api/v1/remote_configs',
	GET_SEARCH: GET_SEARCH[getEnvironment()],
	GET_APPLIST: GET_APPLIST[getEnvironment()],
	GET_ENTITLEMENT: GET_ENTITLEMENT[getEnvironment()],
	GET_CDN_ACCESS_TOKEN: GET_CDN_ACCESS_TOKEN[getEnvironment()],
	// AUTHENTICATION_SIGNUP_REDIRECT: AUTHENTICATION_SIGNUP_REDIRECT[getEnvironment()],
	// GET_CHANNEL_BY_ID: `epg/${switchVersionEnv(key.VERSION) || API_VERSION}/getEpgChannel;platformId=32faad53-5e7b-4cc0-9f33-000092e85950;channelId={CHANNEL_ID}`,
	// ENTITLEMENT_BASE_URL: ENTITLEMENT_BASE_URL[getEnvironment()],
	// GET_CHANNELS_FILTER: `epg/${switchVersionEnv(key.VERSION) || API_VERSION}/getEpgChannelGenres`,
	// GET_EDITORIAL: `editorial/${switchVersionEnv(key.VERSION) || API_VERSION}/getEditorialsForHome;${PRODUCT_PLATFORM_PACKAGE_ID}`,
	// GET_CHANNEL_SCHEDULES: `https://ssl.dstv.com/api/cs-mobile/epg/v1/getEpgSchedulesByTag;startDate=2019-01-12;endDate=2019-01-13;channelTags=E10,HDT`,
	// GET_EDITORIAL_ITEM: `editorial/${switchVersionEnv(key.VERSION) || API_VERSION}/getEditorialsForItem;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};videoId={VIDEO_ID}`,
	// GET_SECTIONS: `${switchVersionEnv(key.VERSION) || API_VERSION}/application/vod/sections;${PRODUCT_PLATFORM_PACKAGE_ID}`,
	GET_VIDEO: `now-content/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};videoId={VIDEO_ID}`,

	// GET_PROGRAM: GET_PROGRAM[getEnvironment()],
	// GET_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/getBookmark;genref={GENREF}`,
	// GET_BOOKMARKS: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/bookmarks;genref={GENREF}`,
	// GET_ALL_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/getAllBookmarks`,
	// SAVE_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/saveBookmark`,

	GET_PROGRAM: `now-content/v7/getCatalogue;${PRODUCT_PLATFORM_PACKAGE_ID};programId={PROGRAM_ID}`,
	GET_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/getBookmark;genref={GENREF}`,
	GET_BOOKMARKS: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/bookmarks;genref={GENREF}`,
	GET_ALL_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/getAllBookmarks`,
	SAVE_BOOKMARK: `bookmark-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/saveBookmark`,

	// GET_TVGUIDE: `http://guide.dstv.com/`,
	// GET_ZATVGUIDE: `https://www.dstv.co.za/whats-on/tv-guide/view-the-tv-guide/`,
	// BOX_OFFICE_RENT: `https://ssl.dstv.com/api/dstv_now/boxoffice_rentals/`,
	// GET_SHOWMAX: `https://now.dstv.com/showmax`,
	// LINK_MY_DSTV: 'https://connect.dstv.com/4.1/Smartcards',
	GET_TRY_THIS: `editorial/${
		switchVersionEnv(key.VERSION) || API_VERSION
	}/getEditorialsForItem;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};videoId={VIDEO_ID}`,
	// GET_ENTITLEMENT_STREAMA: `user-manager/v5/vod-authorisation;${PRODUCT_PLATFORM_ID_STREAMA}`,
	// GET_DEVICE_TYPE: DEVICE_TYPE,
	// AUTHENTICATION_REDIRECT: AUTHENTICATION_REDIRECT[getEnvironment()],
	// GET_ENTITLEMENT: `user-manager/v5/vod-authorisation;${window.deployment_platform === 'explora' ? EXPLORA_PRODUCT_PLATFORM_ID : PRODUCT_PLATFORM_ID_DEVICE_TYPE}`,
	// GET_CURRENT_EVENT: `epg/${switchVersionEnv(key.VERSION) || API_VERSION}/getCurrentEventByTag;channelTag={channelTag}`,
	// GET_SECTIONS_LIVE: `epg/${switchVersionEnv(key.VERSION) || API_VERSION}/sections;${CONFIG.PLATFORM_ID}`,
	// GET_SECTIONS_CHANNELS_GROUPS: `https://ssl.dstv.com/api/lists/channel_group_sections?country_code={COUNTRY_CODE}&subscription_package={PACKAGE}`,
	// //GET_SECTIONS_CHANNELS_GROUPS: `https://1j754pelwi.execute-api.eu-west-1.amazonaws.com/api/lists/channels_groups_sections`,
	// GET_CATALOGUE: `now-content/${switchVersionEnv(key.VERSION) || API_VERSION}/getCatalogue;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};${PAGE_SIZE}`,
	// SESSION_REFRESH: SESSION_REFRESH_ENV[getEnvironment()],
	// GET_FOOTER: `now-content/${switchVersionEnv(key.VERSION) || API_VERSION}/footer;${PRODUCT_PLATFORM_ID_DEVICE_TYPE}`,
	// GET_BILLBOARD: `now-content/${switchVersionEnv(key.VERSION) || API_VERSION}/billboard;${PRODUCT_PLATFORM_ID_DEVICE_TYPE}`,
	// GET_CONCURRENCY_STATUS: `user-manager/${switchVersionEnv(key.VERSION) || API_VERSION}/getConcurrentSession;${PRODUCT_PLATFORM_ID_DEVICE_TYPE};deviceId={DEVICE_ID};sessionType={SESSION_TYPE};platformType={PLATFORM_TYPE};assetTag={ASSET_TAG}`,
	// VERIFY_OTP: VERIFY_OTP[getEnvironment()],
	// SHOWMAX_WHITELOGO_URL: 'https://now.dstv.com/Content/img/showmax-logo.png',
	// SHOWMAX_CATEGORY_LINK: `https://api.showmax.com/v${SHOWMAX_API_VERSION}/website/catalogue/promos`,
	// SHOWMAX_CATEGORY_ITEMS: `https://api.showmax.com/v${SHOWMAX_API_VERSION}/website/catalogue/by_id/`,
	// SHOWMAX_ELIGIBILITY_LINK: `showmax/${switchVersionEnv(key.VERSION) || API_VERSION}/eligibility`,
	// LEANBACK_HELP_LINK: 'http://www.dstv.com/topic/dstv-now-faq-20161110',
	CREATE_PROFILE: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles`,
	UPDATE_OR_DELETE_PROFILE: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles/{PROFILE_ID}`,
	GET_AVATARS_LIST: `${switchVersionEnv(key.VERSION) || API_VERSION}/profiles/avatars`,
	// SHOWMAX_ACTIVATION_CODE_LINK: 'showmax/v4/activationCode',
	// SHOWMAX_ACTIVATION_LINK: 'https://secure.showmax.com/payment/subscriptions/partners/dstvza',
	// SHOWMAX_SWITCH_ACCOUNT_LINK: 'https://selfservice.dstv.com/MyDStv/ManageDebitOrder?utm_source=showmax&utm_medium=referral&utm_campaign=showmax_managedebitorder&utm_content=showmax_managedebitorer#/manageDebitOrder',
	// SHOWMAX_UPGRADE_ACCOUNT_LINK: 'https://selfservice.dstv.com/MyDStv/ChangeMyPackages?utm_source=showmax&utm_medium=referral&utm_campaign=showmax_changepackages&utm_content=showmax_changepackages#/changePackages',
	// PLAYREADY: 'https://license.dstv.com/playready/rightsmanager.asmx?CrmId=afl&AccountId=afl&SubContentType=default',
	// WIDEVINE: 'https://license.dstv.com/widevine/getLicense?CrmId=afl&AccountId=afl',
	WIDEVINE: 'https://licensev2.dstv.com/widevine/getLicense?CrmId=afl&AccountId=afl',
	WATCH_LIST: `${switchVersionEnv(key.VERSION) || API_VERSION}/watchlist;${PRODUCT_PLATFORM_ID_DEVICE_TYPE}`,
	// SHOWMAX_TERMS_LINK: 'https://www.dstv.co.za/legal/terms-and-conditions/dstv-customers-adding-showmax-to-their-dstv-account/',
	// MY_LIST: `editorial/${API_VERSION}/editorialList/my-list;${PRODUCT_PLATFORM_PACKAGE_ID}`,
	AUTO_PLAY_NEXT_EPISODE: AUTO_PLAY_NEXT_EPISODE[getEnvironment()],
	GET_AD_REQUEST_API_URL: GET_AD_REQUEST_API_URL[getEnvironment()],
	UPDATE_WATCHBUTTON_API: UPDATE_WATCHBUTTON_API[getEnvironment()],
	// STATIC_PAGE_CONTENT: STATIC_PAGE_CONTENT[getEnvironment()],
	// PACKAGE_UPGRADE: `https://www.dstv.co.za/get-dstv/`,
	// BOXOFFICE_RENTAL_MOVIES_URL: GET_RENTAL_MOVIES_URL[getEnvironment()],
	// GET_MOVIE: `{MOVIE_ID}`,
	// BOXOFFICE_BASE_URL: GET_BOXOFFICE_BASE_URL[getEnvironment()],
	// BOXOFFICE_ALLOW_PAYMENT_METHODS: GET_BOXOFFICE_ALLOW_PAYMENT_METHODS[getEnvironment()],
	// BOXOFFICE_RENTAL_URL: GET_BOXOFFICE_RENTAL_URL[getEnvironment()],
	// GET_MOVIE_ENTITLEMENT: GET_MOVIE_ENTITLEMENT[getEnvironment()],
	// GET_APPLIST: GET_APPLIST[getEnvironment()],
	// GET_GRANULAR_BASE_URL: `https://ssl.dstv.com/api/dstv_now`,
	// GET_CONTINUE_WATCHING_URL: `https://ssl.dstv.com/api/dstv_now/lists/continue_watching/${CONFIG.PLATFORM_ID}`,
	// QR_CODE_REDIRECT_URL: QR_CODE_REDIRECT_URL[getEnvironment()],
	TERMS_AND_CONDITIONS: TERMS_AND_CONDITIONS[getEnvironment()],
	// EMAIL_SIGN_IN_URL: EMAIL_SIGN_IN_URL[getEnvironment()],
	// EMAIL_SIGN_UP_URL: EMAIL_SIGN_UP_URL[getEnvironment()],
	// EMAIL_PASSWORD_RESET_URL: EMAIL_PASSWORD_RESET_URL[getEnvironment()],
	// GET_PACKAGE_LIST: GET_PACKAGE_LIST[getEnvironment()],
	// GET_SUBSCRIPTION_LINKED_STATUS: GET_SUBSCRIPTION_LINKED_STATUS[getEnvironment()],
}
