import { createSlice } from '@reduxjs/toolkit'

import { mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { v4 as uuidv4 } from 'uuid'

import { GET_ENTITLEMENT } from '../entitlement/entitlement.slice'
import { ENV } from '../../utils/environment'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { errorResponse, HTTP } from '../../utils/httpHelper'
import { secureLocalStorage } from '../../utils/secureStorage'
import { getVodDetails } from '../billboard/billboardTransformHelper'
import { getCardPosterImageURL } from '../../utils/transformHelper'
import { GET_CDN_TOKEN } from '../cdn-auth/cdn-auth.slice'
import { ErrorMessages } from '../../utils/errorCodeHelper'

export const PLAYER_FEATURE_KEY = 'player'

const initialState = {
	bookmark: null,
	details: undefined,
	error: null,
	type: null,
	url: null,
	isPlaying: null,
	tryThis: null,
	loading: false,
}

/*
 * Create our slice
 */
export const playerSlice = createSlice({
	name: PLAYER_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		DISPLAY_NOTIFICATION_OVERLAY: (state, action) => state,
		NOTIFICATION_OVERLAY_BUTTON_CLICKED: (state, action) => state,
		GET_BOOKMARK: (state) => {
			state.bookmark = null
			state.bookmarkError = null
			state.loading = true
		},
		GET_BOOKMARK_SUCCESS: (state, action) => {
			state.bookmark = action.payload.bookmark
			state.loading = false
		},
		GET_BOOKMARK_ERROR: (state) => {
			state.loading = false
			state.bookmark = 0
		},
		GET_PLAYER_DETAILS: (state) => {
			Object.assign(state, initialState)
			state.loading = true
		},
		PLAY: (state, action) => {
			Object.assign(state, initialState)
			state.ignoreBookmarks = action.payload.ignoreBookmarks
			state.details = action.payload.details
			state.type = action.payload.type
			state.url = action.payload.url
			state.trackingPlayId = uuidv4()
			state.isPlaying = true
			state.seeking = false
		},
		PAUSE: (state, action) => {
			state.isPlaying = action.payload
			state.seeking = false
		},
		RESUME_PLAY: (state, action) => {
			state.isPlaying = action.payload
			state.seeking = false
		},
		RESET_PLAYER: (state) => {
			Object.assign(state, initialState)
		},
		PLAYER_ERROR: (state, action) => {
			state.error = action.payload?.error ?? getErrorMessageType(action.payload)
			state.serverError = action.payload?.serverError
		},
		RESET_BOOKMARK: (state) => {
			state.bookmark = 0
		},
		SAVE_BOOKMARK: (state) => {
			state.loading = true
			state.bookmarkSaved = false
		},
		SAVE_BOOKMARK_SUCCESS: (state) => {
			state.loading = false
			state.bookmarkSaved = true
		},
		SAVE_BOOKMARK_ERROR: (state, action) => {
			state.loading = false
		},
		RESET_TRY_THIS: (state) => {
			state.loading = true
			state.tryThis = null
		},
		GET_TRY_THIS: (state) => {
			state.loading = true
			state.tryThis = null
		},
		TRY_THIS_SUCCESS: (state, action) => {
			state.loading = false
			state.tryThis = [action.payload].map((item) => {
				return {
					...item,
					editorialItems: item.editorialItems.map((editorialItem) => {
						return {
							...editorialItem,
							poster_image: getCardPosterImageURL(editorialItem),
						}
					}),
				}
			})
		},
		TRY_THIS_ERROR: (state, action) => {
			state.loading = false
			state.tryThis = null
		},
		SWITCH_CHANNEL: (state, action) => {
			state.loading = true
			state.content_discovery_category = action.payload?.details?.content_discovery_category
			state.content_discovery_referrer = 'Live TV Player'
			state.content_discovery_section = 'Live TV'
		},
		SET_CONTENT_DISCOVERY_REFERRER: (state, action) => {
			state.content_discovery_referrer = action.payload.referrer
		},
		CLEAR_CONTENT_DISCOVERY_REFERRER: (state, action) => {
			delete state.content_discovery_referrer
		},
		SAVE_AUDIO_LANGUAGE: (state, action) => {
			if (action.payload.language) {
				state.savedAudioLanguage = action.payload.language
			}
		},
		SAVE_BANDWIDTH: (state, action) => {
			if (action.payload.bandwidth) {
				state.savedBandwidth = action.payload.bandwidth
			}
		},
		SEEK: (state, action) => {
			state.seeking = true
		},
		CLEAR_AUDIO_LANGUAGE_AND_BANDWIDTH: (state, action) => {
			delete state.savedAudioLanguage
			delete state.savedBandwidth
		},
	},
	extraReducers: (builder) => {
		builder.addCase(GET_ENTITLEMENT.type, (state) => {
			delete state.error
			delete state.serverError
		})
		builder.addCase(GET_CDN_TOKEN.type, (state) => {
			delete state.error
			delete state.serverError
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const playerReducer = playerSlice.reducer

/*
 * Export actions
 */
export const {
	DISPLAY_NOTIFICATION_OVERLAY,
	NOTIFICATION_OVERLAY_BUTTON_CLICKED,
	GET_BOOKMARK,
	GET_BOOKMARK_SUCCESS,
	PLAY,
	PAUSE,
	RESUME_PLAY,
	PLAYER_STOPPED,
	RESET_BOOKMARK,
	SAVE_BOOKMARK,
	SAVE_BOOKMARK_SUCCESS,
	SAVE_BOOKMARK_ERROR,
	GET_BOOKMARK_ERROR,
	RESET_TRY_THIS,
	GET_TRY_THIS,
	TRY_THIS_SUCCESS,
	TRY_THIS_ERROR,
	PLAYER_ERROR,
	RESET_PLAYER,
	GET_PLAYER_DETAILS,
	GET_PLAYER_DETAILS_SUCCESS,
	SWITCH_CHANNEL,
	SAVE_AUDIO_LANGUAGE,
	SAVE_BANDWIDTH,
	SEEK,
	CLEAR_AUDIO_LANGUAGE_AND_BANDWIDTH,
	SET_CONTENT_DISCOVERY_REFERRER,
	CLEAR_CONTENT_DISCOVERY_REFERRER,
} = playerSlice.actions

/*
 * Set up the redux-observable epic
 */
export const playerEpic = (action$) =>
	action$.pipe(
		ofType(GET_BOOKMARK.type, SAVE_BOOKMARK.type, GET_PLAYER_DETAILS.type, GET_TRY_THIS.type),
		mergeMap(playerService(action$))
	)

/*
 * Do API calls
 */
const playerService = (action$) => (action) => {
	switch (action.type) {
		case GET_BOOKMARK.type: {
			let bookmarkUrl = ENV.GET_BOOKMARK.replace('{GENREF}', action.payload.id.split('_')[1] || action.payload.id)
			return HTTP.GET(bookmarkUrl, getHeaders(), getBookmarkSuccess(action), getBookmarkError(action))
		}
		case SAVE_BOOKMARK.type: {
			return HTTP.POST(
				ENV.SAVE_BOOKMARK,
				action.payload,
				getHeaders(),
				saveBookmarkSuccess,
				saveBookmarkError(action.payload)
			)
		}
		case GET_PLAYER_DETAILS.type: {
			let url = ENV.BASE_URL + ENV.GET_VIDEO.replace('{VIDEO_ID}', action.payload.videoId)
			return HTTP.GET(url, getHeaders(), getPlayerDetailsSuccess(action), playerError(action), true)
		}
		case GET_TRY_THIS.type: {
			return HTTP.GET_WITH_CANCEL(
				ENV.BASE_URL + ENV.GET_TRY_THIS.replace('{VIDEO_ID}', action.payload.videoId.replace('IS20_', '')),
				getHeaders(),
				tryThisSuccess,
				tryThisError(action),
				false,
				action$.pipe(ofType(GET_TRY_THIS.type))
			)
		}
	}
}

/*
 * Dispatch actions based on API responses
 */
const getBookmarkSuccess = () => (response) => {
	return { type: GET_BOOKMARK_SUCCESS.type, payload: { bookmark: response?.timeInSeconds } }
}

const saveBookmarkSuccess = (payload) => (response) => {
	secureLocalStorage.setItem('playback-bookmark', JSON.stringify(payload))
	return { type: SAVE_BOOKMARK_SUCCESS.type }
}

const getPlayerDetailsSuccess = (action) => (response) => {
	return {
		type: PLAY.type,
		payload: getVodDetails(response?.items?.[0], action?.payload?.resumeVideoId),
	}
}

const getBookmarkError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: GET_BOOKMARK_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const playerError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: PLAYER_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const saveBookmarkError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: SAVE_BOOKMARK_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const tryThisSuccess = (response) => {
	const tryThis = response?.items?.find((row) => row?.editorialItems?.length && row?.name === 'Try This')

	if (tryThis) {
		return { type: TRY_THIS_SUCCESS.type, payload: tryThis }
	} else {
		return of({
			type: TRY_THIS_ERROR.type,
		})
	}
}

const tryThisError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: TRY_THIS_ERROR.type,
				payload: errorResponse(response, action),
			},
			action
		)
	)
}

const getErrorMessageType = (payload) => {
	let errorDetails = payload?.details?.detail?.data?.[0]?.data?.[2]
	errorDetails = errorDetails && JSON.parse(errorDetails)
	if (errorDetails?.code === 130401) return ErrorMessages.getErrorMessageByName('concurrency')
	if (errorDetails?.code === 130402) return ErrorMessages.getErrorMessageByName('proximity')

	return
}
