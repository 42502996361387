import { createSlice } from '@reduxjs/toolkit'
import { getHeaders, handleUnauthorized } from '../../utils/authHelper'
import { ENV } from '../../utils/environment'
import { mergeMap } from 'rxjs/operators'
import { HTTP } from '../../utils/httpHelper'
import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import { getBillboardData } from './billboardTransformHelper'
import { SET_ACTIVE_PROFILE_ID } from '../profile/profile.slice'

export const BILLBOARD_FEATURE_KEY = 'billboard'

const initialState = {
	title: null,
	data: null,
	loading: true,
}

export const billboardSlice = createSlice({
	name: BILLBOARD_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		CLEAR_BILLBOARD_INFO: (state) => {
			state.info = null
		},
		SET_BILLBOARD_INFO: (state, action) => {
			state.info = action.payload.details
			state.title = action.payload.details.title
			state.progress = action.payload.progress
			state.displayItemDetailedTitle = action.payload.displayItemDetailedTitle
			state.loading = false
		},
		GET_BILLBOARD_DATA: (state, action) => {
			state.loading = true
			state.videoId = action.payload.videoId
			state.info = null
		},
		BILLBOARD_SUCCESS: (state, action) => {
			state.loading = false
			state.data = action.payload.data
			state.date = action.payload.date
		},
	},
	extraReducers: (builder) => {
		builder.addCase(SET_ACTIVE_PROFILE_ID.type, (state) => {
			Object.assign(state, initialState)
		})
	},
})

/*
 * Export reducer for store configuration.
 */
export const billboardReducer = billboardSlice.reducer

/*
 * Export actions
 */
export const { CLEAR_BILLBOARD_INFO, SET_BILLBOARD_INFO, GET_BILLBOARD_DATA, BILLBOARD_SUCCESS } =
	billboardSlice.actions

/*
 * Set up the redux-observable epic
 */
export const billboardEpic = (action$) =>
	action$.pipe(ofType(GET_BILLBOARD_DATA.type), mergeMap(billboardService(action$)))

/*
 * Do API calls
 */
export const billboardService = (action$) => (action) => {
	switch (action.type) {
		case GET_BILLBOARD_DATA.type: {
			let showPageBillboardApi =
				action.payload.editorialItemType === 'Program'
					? ENV.GET_PROGRAM.replace('{PROGRAM_ID}', action.payload.videoId)
					: ENV.GET_VIDEO.replace('{VIDEO_ID}', action.payload.videoId)
			return HTTP.GET(ENV.BASE_URL + showPageBillboardApi, getHeaders(), billboardSuccess(action), billboardError)
		}
	}
}

const billboardSuccess = (action) => (response) => {
	const payload = getBillboardData(response?.items?.length && response?.items[0], action.payload.videoId)

	return {
		type: BILLBOARD_SUCCESS.type,
		payload: {
			...payload,
		},
	}
}

const billboardError = (action) => (response) => {
	return of(
		handleUnauthorized(
			response,
			{
				type: BILLBOARD_SUCCESS.type,
				payload: {
					data: null,
					videoId: action.payload.videoId,
					date: new Date().getTime(),
				},
			},
			action
		)
	)
}
