import base64 from 'base64-js'
import { getAdsPlatformName } from '@dstv-web-leanback/dstv-frontend-services'

export const getPlayerConfiguration = (sessionId, type, contentDetails, config) => {
	let contentId = type === 'live' ? contentDetails.channelTag : contentDetails.contentId

	return {
		abr: { ...prepareConfigObject(config?.shaka_abr_config?.payload) },
		drm: {
			servers: {
				'com.widevine.alpha': `https://licensev2.dstv.com/widevine/getLicense?CrmId=afl&AccountId=afl&ContentId=${contentId}&ls_session=${sessionId}`,
			},
			advanced: {
				'com.widevine.alpha': {
					audioRobustness: 'SW_SECURE_CRYPTO',
					videoRobustness: 'SW_SECURE_CRYPTO',
					persistentStateRequired: Boolean(process.env.NX_PERSISTENT_STATE_REQUIRED) || false,
				},
			},
		},
		streaming: {
			...prepareConfigObject(config?.enable_cv_player?.payload),
			retryParameters: {
				...prepareConfigObject(config?.shaka_retry_config?.payload),
			},
		},
	}
}

export const getStreamUrl = (type, manifestUrl, ucp_filter, cdnAuthToken, ssaiQueryParam) => {
	const hdcpFilters = ucp_filter
	const pulseAdsSSAIStr = ssaiQueryParam ? '?&ssai=' + ssaiQueryParam : ''
	const watermarkingToken = encodeURIComponent(cdnAuthToken)

	let playerUrl = manifestUrl.split('?')[0] + '/.mpd'
	const restParams = manifestUrl.split('?')[1]
	if (type === 'live') {
		playerUrl = playerUrl + pulseAdsSSAIStr + hdcpFilters + '&hdnts=' + watermarkingToken
	} else {
		playerUrl = playerUrl + hdcpFilters
	}
	return playerUrl + (restParams ? '&' + restParams : '')
}

export const getPulseAdsConfig = (ssai_enabled, type, pid, userPackage) => {
	if (ssai_enabled.flagValue === 'true' && type === 'live') {
		const pulseAdsConfig = {
			t: `PR:Dstv,PA:${userPackage},SC:Linear,AO:${
				ssai_enabled?.payload?.scheduling === 'Linear' ? ssai_enabled.payload.status : ''
			}`,
			gdpr: 0,
			gdpr_consent: 0,
			gdpr_pd: 0,
			pid: pid,
			dcid: getAdsPlatformName(),
			pf: 'html5',
		}
		return preparePulseAdParameters(pulseAdsConfig)
	}

	return ''
}

export const preparePulseAdParameters = (pulseAdsConfig) => {
	let queryParam = ''

	Object.keys(pulseAdsConfig).forEach((key) => {
		if (
			typeof pulseAdsConfig[key] === 'object' &&
			pulseAdsConfig[key] !== null &&
			Object.keys(pulseAdsConfig[key]).length > 0
		) {
			Object.keys(pulseAdsConfig[key]).forEach((j) => {
				queryParam += (!queryParam ? '' : '&') + (key + '.' + j) + '=' + pulseAdsConfig[key][j]
			})
		} else {
			queryParam += (!queryParam ? '' : '&') + key + '=' + pulseAdsConfig[key]
		}
	})

	const textEncoder = new TextEncoder()
	const bytes = textEncoder.encode(queryParam)
	const encodedString = base64.fromByteArray(bytes)

	return encodedString
}

const prepareConfigObject = (originalConfig) => {
	let parsedConfig = {}

	for (let key in originalConfig) {
		try {
			parsedConfig[key] = originalConfig[key].includes('.')
				? parseFloat(originalConfig[key])
				: parseInt(originalConfig[key])
		} catch (err) {
			parsedConfig[key] = null
		}
	}

	return parsedConfig
}
