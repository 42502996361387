export const configKeyEnabled = (configState, key) => {
	try {
		return configState.data[key].flagValue === 'true'
	} catch (e) {
		// console.error("Error: Remote config key doesn't exist.")
		return false
	}
}

export const getConfigPayloadValue = (configState, key, payloadKey) => {
	try {
		return configState.data[key].payload[payloadKey]
	} catch (e) {
		// console.error("Error: Couldn't find the requested value.")
		return
	}
}
