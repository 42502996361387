import { useState } from 'react'
import { FocusContext, useFocusable } from '@dstv-web-leanback/norigin-spatial-navigation'
import styles from './ProfileImage.module.scss'

export function ProfileImage({ imageType, alias, avatarUri, isEditFocused, onSelectItem, onItemFocused, from, index }) {
	const [selected, setSelected] = useState(false)

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { ref, focused, focusKey } = useFocusable({
		focusable: true,
		onEnterPress: () => {
			if (imageType === 'avatar') {
				setSelected(true)
			}
			onSelectItem()
		},
		onFocus: () => onItemFocused(ref),
		onBlur: () => {
			if (imageType === 'avatar') setSelected(false)
		},
		focusKey: `USER_ACCOUNT_${index}`,
	})
	let imageStyle = (imageType ? imageType : 'profile') + '__img'
	if (selected) {
		imageStyle = imageStyle + '__active'
	} else if (focused) {
		imageStyle = imageStyle + '__focus'
	} else if (isEditFocused) {
		imageStyle = imageStyle + '__focus_edit_on'
	}

	const additionalStyle = from === 'save_page' ? { width: '100%', textAlign: 'center' } : {}
	return (
		<FocusContext.Provider value={focusKey}>
			<div ref={ref} style={additionalStyle} key={avatarUri}>
				<img key={avatarUri} alt={alias} src={avatarUri} className={styles[`${imageStyle}`]} />
			</div>
		</FocusContext.Provider>
	)
}

export default ProfileImage
